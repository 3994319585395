import { Bell, X } from "lucide-react";
import { DialogTrigger, Separator } from "react-aria-components";

import { AccessibleIcon } from "@/components/accessible-icon";
import { Button } from "@/components/button";
import { Dialog } from "@/components/dialog";
import { MenuButton } from "@/components/menu";
import { NotificationBadge } from "@/components/notification-badge";
import { Popover } from "@/components/popover";

type NotificationsLayoutProps = {
  children: React.ReactNode;
  hasNewNotifications: boolean;
  header?: React.ReactNode;
};

export function NotificationsLayout({
  children,
  hasNewNotifications,
  header,
}: NotificationsLayoutProps) {
  return (
    <DialogTrigger>
      <MenuButton isIconOnly noIndicator variant="plain">
        <AccessibleIcon className="text-nav-item-button-icon-fg">
          <Bell />
        </AccessibleIcon>
        {hasNewNotifications && (
          <NotificationBadge aria-label="New notifications" variant="dot" />
        )}
      </MenuButton>
      <Popover className="w-[420px] bg-bg-primary pt-md shadow-none">
        <Dialog className="flex h-full max-h-[416px] flex-col">
          {({ close }) => (
            <>
              <div className="flex flex-col">
                <div className="flex items-center justify-between px-lg py-md">
                  <span className="text-md font-semibold text-text-primary">
                    Notifications
                  </span>
                  <Button onPress={close} variant="unstyled">
                    <AccessibleIcon>
                      <X size={16} />
                    </AccessibleIcon>
                  </Button>
                </div>
                {header}
                <Separator className="my-0 w-full self-center border-t border-border-secondary py-0" />
              </div>
              {children}
            </>
          )}
        </Dialog>
      </Popover>
    </DialogTrigger>
  );
}
