import type { Dispatch, SetStateAction } from "react";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { convert } from "html-to-text";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";

import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";

const fileStatusSchema = z.enum(["error", "success", "uploading"]);

const fileSchema = z.instanceof(File);

const fileAssetSchema = z.object({
  attachmentId: z.number().nullable(),
  awsKey: z.string().nullable(),
  file: fileSchema,
  id: z.string(),
  status: fileStatusSchema,
});

const linkAssetSchema = z.object({
  attachmentId: z.number(),
  id: z.string(),
  url: z.string().url(),
});

const createGraphicRequestFormSchema = z.object({
  /* eslint-disable perfectionist/sort-objects */
  requestName: z.string().min(1, { message: "Request name is required" }),
  designType: z.number({
    required_error: "Design type is required",
  }),
  brand: z.coerce.number().optional(),
  // Brief creator
  briefCreatorType: z.enum(["detailed", "single"]),
  briefCreatorDetailed: z.object({
    additionalGuidelines: z.string(),
    brandGuidelines: z.string(),
    creativeGoal: z.string(),
    keyMessageAndCta: z.string(),
    scope: z.string(),
    finalBriefHTML: z.string(),
    finalBriefText: z.string(),
  }),
  briefCreatorSingle: z.object({
    requestDescriptionHTML: z.string(),
    requestDescriptionText: z.string(),
    textsForDesignHTML: z.string(),
    textsForDesignText: z.string(),
  }),
  // Assets and inspirations
  assets: z.array(z.union([fileAssetSchema, linkAssetSchema])),
  inspirations: z.array(z.union([fileAssetSchema, linkAssetSchema])),
  // Technical details
  creativeDirection: z
    .union([
      z.literal("Designer has creative freedom"),
      z.literal("Follow brief exactly"),
    ])
    .optional(),
  sizes: z.string().min(1, { message: "Sizes are required" }),
  fileTypes: z.string().min(1, { message: "File types are required" }),
  preferredDesigners: z.array(z.string()),
  collaborators: z.array(z.string()),
  /* eslint-enable perfectionist/sort-objects */
});

export type CreateGraphicRequestFormData = z.infer<
  typeof createGraphicRequestFormSchema
>;
export type FileStatus = z.infer<typeof fileStatusSchema>;
export type FileAsset = z.infer<typeof fileAssetSchema>;
export type LinkAsset = z.infer<typeof linkAssetSchema>;

export function useCreateGraphicRequestForm(): {
  setShowWarningModal: Dispatch<SetStateAction<boolean>>;
  showWarningModal: boolean;
} & UseFormReturn<CreateGraphicRequestFormData> {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const setIsDirty = useIsRequestFormDirty((state) => state.setIsDirty);

  const form = useForm<CreateGraphicRequestFormData>({
    defaultValues: {
      /* eslint-disable perfectionist/sort-objects */
      requestName: "",
      designType: undefined,
      brand: undefined,
      briefCreatorType: "detailed",
      briefCreatorDetailed: {
        additionalGuidelines: "",
        brandGuidelines: "",
        creativeGoal: "",
        keyMessageAndCta: "",
        scope: "",
        finalBriefHTML: "",
        finalBriefText: "",
      },
      briefCreatorSingle: {
        requestDescriptionHTML: "",
        requestDescriptionText: "",
        textsForDesignHTML: "",
        textsForDesignText: "",
      },
      assets: [],
      inspirations: [],
      creativeDirection: undefined,
      sizes: "",
      fileTypes: "",
      preferredDesigners: [],
      collaborators: [],
      /* eslint-enable perfectionist/sort-objects */
    },
    resolver: zodResolver(
      createGraphicRequestFormSchema.superRefine((data, ctx) => {
        if (data.briefCreatorType === "detailed") {
          // Case 1: No scope and no final brief
          if (
            data.briefCreatorDetailed.scope.length === 0 &&
            convert(data.briefCreatorDetailed.finalBriefHTML).trim().length ===
              0
          ) {
            // Only show warning modal during actual submission attempts
            if (form.formState.isSubmitting) {
              setShowWarningModal(true);
            }
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please fill in the scope or generate a brief",
              path: ["briefCreatorDetailed", "finalBriefHTML"],
            });
          }

          // Case 2: Has scope but no final brief
          if (
            data.briefCreatorDetailed.scope.length > 0 &&
            convert(data.briefCreatorDetailed.finalBriefHTML).trim().length ===
              0
          ) {
            // Only show warning modal during actual submission attempts
            if (form.formState.isSubmitting) {
              setShowWarningModal(true);
            }
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                "The brief text is required. Please discard or regenerate to submit a request",
              path: ["briefCreatorDetailed", "finalBriefHTML"],
            });
          }
        } else if (data.briefCreatorType === "single") {
          // Validate single brief fields
          if (
            convert(data.briefCreatorSingle.requestDescriptionHTML).trim()
              .length === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Request description is required",
              path: ["briefCreatorSingle", "requestDescriptionHTML"],
            });
          }
          if (
            convert(data.briefCreatorSingle.textsForDesignHTML).trim()
              .length === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Text for design is required",
              path: ["briefCreatorSingle", "textsForDesignHTML"],
            });
          }
        }
      }),
    ),
  });

  // Handle beforeunload event to prevent unsaved changes from being lost
  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";

        return event.returnValue;
      }
    },
    [form.formState.isDirty],
  );

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty, setIsDirty]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  useEffect(() => {
    const unsubscribe = useIsRequestFormDirty.subscribe(
      (state) => state.isDirty,
      (isDirty) => {
        if (!isDirty) {
          form.reset(form.getValues());
        }
      },
    );

    return () => unsubscribe();
  }, [form]);

  return {
    ...form,
    setShowWarningModal,
    showWarningModal,
  };
}

export function useCreateGraphicRequestFormContext() {
  return useFormContext<CreateGraphicRequestFormData>();
}
