import { useEffect, useState } from "react";

export function useTheme(): { isDark: boolean; toggleTheme: () => void } {
  const [isDark, setIsDark] = useState(() =>
    document.documentElement.classList.contains("dark"),
  );

  useEffect(() => {
    const theme = document.cookie
      .split("; ")
      .find((row) => row.startsWith("theme="))
      ?.split("=")[1];

    const shouldBeDark = theme === "dark-mode";

    setIsDark(shouldBeDark);
    if (shouldBeDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const toggleTheme = () => {
    const newIsDark = !isDark;

    setIsDark(newIsDark);

    const expiryDate = new Date();

    expiryDate.setDate(expiryDate.getDate() + 30);

    document.cookie = `theme=${newIsDark ? "dark-mode" : "light-mode"}; expires=${expiryDate.toUTCString()}; path=/`;

    if (newIsDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return { isDark, toggleTheme };
}
