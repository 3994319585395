import { useIsMutating } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";

import { Alert } from "@/components/alert";
import { Radio, RadioGroup, Radios } from "@/components/radio-group";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";
import { useAlert } from "./hooks/useAlert";
type TabsHeaderProps = {
  isDisabled: boolean;
};

export function TabsHeader({ isDisabled }: TabsHeaderProps) {
  const methods = useCreateVideoRequestFormContext();
  const formType = methods.watch("briefCreatorType");

  const { hideAlert, isAlertHidden } = useAlert("check-mbf");

  const isPending = Boolean(
    useIsMutating({
      mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
      status: "pending",
    }),
  );

  const isBlocked = isDisabled || isPending;

  const options = [
    {
      description: "Smart brief categories for better results",
      name: "Magical Brief Form",
      value: "detailed",
    },
    {
      description: "Classic Submission Method",
      name: "Classic Brief Form",
      value: "single",
    },
  ] as const;

  return (
    <div className="col-span-1 row-span-1 flex w-full flex-col gap-[10px]">
      <span className="text-lg font-semibold text-text-secondary">
        Brief Creator
      </span>
      {isAlertHidden ? null : (
        <Alert
          description="Check the new Magical Brief Form and let the AI help you!"
          onClick={hideAlert}
          title="Creating your request from scratch?"
        />
      )}
      <RadioGroup
        onChange={(value) =>
          methods.setValue("briefCreatorType", value as "detailed" | "single")
        }
        value={formType}
      >
        <Radios className="grid grid-cols-2 gap-[10px]">
          {options.map((option) => (
            <Radio
              className={({ isSelected }) =>
                twMerge(
                  "items-start rounded-xl border border-border-primary p-xl [&_[slot=radio]]:mt-[3px]",
                  isSelected &&
                    "border-border-brand-solid ring-2 ring-border-brand-solid disabled:border-border-disabled-subtle disabled:ring-border-disabled-subtle",
                  "disabled:bg-bg-disabled-subtle",
                )
              }
              isDisabled={isBlocked}
              key={option.value}
              value={option.value}
            >
              <div className="flex flex-1 flex-col gap-0">
                <div className="text-sm font-medium text-text-secondary">
                  {option.name}
                </div>
                <div className="text-sm text-text-tertiary">
                  {option.description}
                </div>
              </div>
            </Radio>
          ))}
        </Radios>
      </RadioGroup>
    </div>
  );
}
