import { useMutation, useMutationState } from "@tanstack/react-query";
import { Separator } from "react-aria-components";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import { toast } from "@/components/toast/toast-queue";
import { useUser } from "@/hooks/useUser";
import authenticatedApi from "@/lib/api/client";

import type { CreateVideoRequestFormData } from "./components/useCreateVideoRequestForm";

import { Footer } from "../shared/components/Footer";
import { Header } from "../shared/components/Header";
import { SubmitWarningModal } from "../shared/components/SubmitWarningModal";
import { useCreateDraft } from "../shared/useCreateDraft";
import { AssetsSection } from "./components/assets-section";
import { BriefCreatorSection } from "./components/brief-creator-section";
import { IntroSection } from "./components/intro-section";
import { TechnicalDetailsSection } from "./components/technical-details-section";
import { useCreateVideoRequestForm } from "./components/useCreateVideoRequestForm";

const SubmitRequestBodyZod = z.object({
  attachment_ids: z.array(z.number()).optional(),
  brand_id: z.number().optional(),
  brief_text: z.coerce.string().optional(), // for magical brief
  ca_type: z.string().optional(), // creative direction
  collaborator_ids: z.array(z.number()).optional(),
  description: z.string(), // for single brief
  design_type_id: z.number(),
  is_draft: z.number(),
  length_or_platform: z.string().optional(),
  music_genre: z.string().optional(),
  name: z.string(),
  preferred_designer_ids: z.array(z.number()).optional(),
  preferred_file_type: z.string().optional(),
  request_type: z.string(),
  sizes_needed: z.string(),
  style_of_video: z.string().optional(),
  text_for_design: z.string(), // for single brief
});

const SaveDraftRequestBodyZod = z.object({
  attachment_ids: z.array(z.number()).optional(),
  brand_id: z.number().optional(),
  brief_text: z.coerce.string().optional(),
  ca_type: z.string().optional(),
  collaborator_ids: z.array(z.number()).optional(),
  description: z.string().optional(),
  design_type_id: z.number().optional(),
  is_draft: z.number().optional(),
  length_or_platform: z.string().optional(),
  music_genre: z.string().optional(),
  name: z.string().optional(),
  preferred_designer_ids: z.array(z.number()).optional(),
  preferred_file_type: z.string().optional(),
  request_type: z.string().optional(),
  sizes_needed: z.string().optional(),
  style_of_video: z.string().optional(),
  text_for_design: z.string().optional(),
});

export function CreateVideoRequestForm() {
  const { data: user } = useUser();

  useCreateDraft();

  const data = useMutationState({
    filters: { mutationKey: ["draftId"] },
    select: (mutation) => mutation.state.data,
  });

  const latestRequestId = data.at(-1) as { requestId: number };

  const { setShowWarningModal, showWarningModal, ...form } =
    useCreateVideoRequestForm();

  const submitMutation = useMutation({
    mutationFn: async (formData: CreateVideoRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields = {
        attachment_ids: [
          ...formData.assets.map((asset) => asset.attachmentId),
          ...formData.inspirations.map(
            (inspiration) => inspiration.attachmentId,
          ),
        ],
        brand_id: formData.brand,
        ca_type: formData.creativeDirection,
        collaborator_ids: formData.collaborators.map(Number),
        design_type_id: formData.videoType,
        is_draft: 0,
        length_or_platform: formData.lengthOrPlatform,
        music_genre: formData.musicGenre,
        name: formData.requestName,
        preferred_designer_ids: formData.preferredDesigners.map(Number),
        preferred_file_type: formData.fileTypes,
        request_type: "video" as const,
        sizes_needed: formData.sizes,
        style_of_video: formData.style,
      };

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              brief_text: formData.briefCreatorDetailed.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorDetailed.script,
            }
          : {
              brief_text: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const parsed = SubmitRequestBodyZod.parse({
        ...commonFields,
        ...briefTypeFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}/submit`)
        .post(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to submit request:", error);

      if (error.message.includes("Request name already exists")) {
        form.setError("requestName", {
          message: "Request name already exists",
        });
        form.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error submitting request",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onSuccess: () => {
      toast.add(
        {
          title: "Request submitted successfully",
          type: "success",
        },
        {
          timeout: 5000,
        },
      );

      setTimeout(() => {
        form.reset();

        if (
          user &&
          ["admin", "collaborator", "owner"].includes(user.user.role_name)
        ) {
          window.location.href = "/requests?queued=1";
        }

        window.location.href = "/requests";
      }, 5000);
    },
  });

  const saveDraftMutation = useMutation({
    mutationFn: async (formData: CreateVideoRequestFormData) => {
      if (!latestRequestId) throw new Error("No request ID found");

      const commonFields = {
        attachment_ids: [
          ...formData.assets.map((asset) => asset.attachmentId),
          ...formData.inspirations.map(
            (inspiration) => inspiration.attachmentId,
          ),
        ],
        brand_id: formData.brand,
        ca_type: formData.creativeDirection,
        collaborator_ids: formData.collaborators.map(Number),
        design_type_id: formData.videoType,
        length_or_platform: formData.lengthOrPlatform,
        music_genre: formData.musicGenre,
        name: formData.requestName,
        preferred_designer_ids: formData.preferredDesigners.map(Number),
        preferred_file_type: formData.fileTypes,
        request_type: "video" as const,
        sizes_needed: formData.sizes,
        style_of_video: formData.style,
      };

      const briefTypeFields =
        formData.briefCreatorType === "detailed"
          ? {
              brief_text: formData.briefCreatorDetailed.finalBriefHTML,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorDetailed.script,
            }
          : {
              brief_text: undefined,
              description: formData.briefCreatorSingle.requestDescriptionHTML,
              text_for_design: formData.briefCreatorSingle.textsForDesignHTML,
            };

      const parsed = SaveDraftRequestBodyZod.parse({
        ...commonFields,
        ...briefTypeFields,
      });

      return authenticatedApi
        .url(`/requests/${latestRequestId.requestId}`)
        .patch(parsed)
        .json<{ message: string; request_data: unknown }>();
    },
    onError: (error) => {
      console.error("Failed to save draft:", error);

      if (error.message.includes("Request name already exists")) {
        form.setError("requestName", {
          message: "Request name already exists",
        });
        form.setFocus("requestName");
      }

      toast.add(
        {
          description: error.message,
          title: "Error saving draft",
          type: "error",
        },
        {
          timeout: 5000,
        },
      );
    },
    onSuccess: () => {
      toast.add(
        {
          title: "Draft saved successfully",
          type: "success",
        },
        {
          timeout: 5000,
        },
      );
    },
  });

  async function onSubmit() {
    const formData = form.getValues();

    submitMutation.mutate(formData);
  }

  async function onSaveDraft() {
    const formData = form.getValues();

    saveDraftMutation.mutate(formData);
  }

  return (
    <>
      <FormProvider {...form}>
        <div className="flex px-4xl">
          <form
            className="mx-auto flex min-w-[820px] max-w-[1500px] flex-col rounded-xl bg-bg-primary"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Header requestName={form.watch("requestName")} />
            <div className="flex-1 overflow-y-auto">
              <div className="flex flex-col gap-3xl px-3xl py-2xl">
                <IntroSection />
                <Separator className="border-border-secondary" />
                <BriefCreatorSection />
                <Separator className="border-border-secondary" />
                <AssetsSection />
                <Separator className="border-border-secondary" />
                <TechnicalDetailsSection />
              </div>
            </div>
            <Footer handleSaveDraft={onSaveDraft} />
          </form>
        </div>
      </FormProvider>
      <SubmitWarningModal
        isOpen={showWarningModal}
        onOpenChange={setShowWarningModal}
      />
    </>
  );
}
