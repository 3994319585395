import { Loader } from "lucide-react";
import { useCallback } from "react";

import { Alert } from "@/components/alert";
import { Button } from "@/components/button";
import { ControlledTiptapEditor } from "@/components/ui/controlled/ControlledTiptapEditor";

import type useAIStream from "./hooks/useAIStream";

import SparklesIcon from "../../../shared/assets/sparkles.svg?react";
import WandIcon from "../../../shared/assets/wand.svg?react";
import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";
import { useAlert } from "./hooks/useAlert";

function PendingState() {
  return (
    <div className="row-span-2 flex h-min flex-col gap-lg rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <div className="flex flex-col">
          <span className="w-full text-sm text-text-secondary">
            Enter your brief details on the left, and enhance the final results
            here.
          </span>
          <span className="text-sm font-bold text-text-brand-secondary">
            To submit, improve your brief with AI!
          </span>
        </div>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button isCustomLoading isDisabled isLoading>
          <Loader className="animate-spin" />
          Improving...
        </Button>
        <span className="text-sm text-text-secondary">
          Enhancements in progress...
        </span>
      </div>
    </div>
  );
}

type SuccessStateProps = {
  handleClearResponse: () => void;
  isPending: boolean;
  onSubmit: () => void;
  streamedResponse: string;
};

function getEditorHeightClassName(
  isSubmissionDetailsHidden: boolean,
  isMagicalBriefFormHidden: boolean,
): string {
  const EDITOR_HEIGHT_CLASSNAMES = {
    ALL_ALERTS: "h-[717px]",
    MBF_ONLY: "h-[840px]",
    NO_ALERTS: "h-[758px]",
    SUBMISSION_DETAILS_ONLY: "h-[632px]",
  };

  switch (true) {
    case isSubmissionDetailsHidden && isMagicalBriefFormHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.NO_ALERTS;
    }
    case isSubmissionDetailsHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.MBF_ONLY;
    }
    case isMagicalBriefFormHidden: {
      return EDITOR_HEIGHT_CLASSNAMES.SUBMISSION_DETAILS_ONLY;
    }
    default: {
      return EDITOR_HEIGHT_CLASSNAMES.ALL_ALERTS;
    }
  }
}

function SuccessState({
  handleClearResponse,
  isPending,
  onSubmit,
}: SuccessStateProps) {
  const { control, setValue } = useCreateVideoRequestFormContext();

  const {
    hideAlert: hideSubmissionDetailsAlert,
    isAlertHidden: isSubmissionDetailsAlertHidden,
  } = useAlert("submission-details");
  const { isAlertHidden: isCheckMagicalBriefFormAlertHidden } =
    useAlert("check-mbf");

  const heightClassName = getEditorHeightClassName(
    isSubmissionDetailsAlertHidden,
    isCheckMagicalBriefFormAlertHidden,
  );

  return (
    <div className="row-span-2 flex h-min flex-col gap-lg rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <div className="flex items-center gap-sm">
          <Button
            className="w-auto"
            color="destructive"
            onPress={handleClearResponse}
            size="sm"
            variant="secondary"
          >
            Discard
          </Button>
          <Button
            className="w-auto"
            isDisabled={isPending}
            onPress={onSubmit}
            size="sm"
            variant="secondary"
          >
            <WandIcon />
            Regenerate
          </Button>
        </div>
      </div>
      {isSubmissionDetailsAlertHidden ? null : (
        <Alert
          description="Submitting will send only the content from Magical Brief Creator.
              To edit the fields or move to Single Form, click ‘Discard’"
          onClick={hideSubmissionDetailsAlert}
          title="Submission Details"
        />
      )}
      <div className="flex flex-col text-left">
        <span className="text-md font-medium text-text-secondary">
          Final Brief
        </span>
        <ControlledTiptapEditor
          className={heightClassName}
          control={control}
          isDisabled={isPending}
          name="briefCreatorDetailed.finalBriefHTML"
          onChangeHTML={(html) =>
            setValue("briefCreatorDetailed.finalBriefHTML", html)
          }
          onChangeText={(text) =>
            setValue("briefCreatorDetailed.finalBriefText", text)
          }
        />
      </div>
    </div>
  );
}

type EmptyStateProps = {
  isPending: boolean;
  onSubmit: () => void;
};

function EmptyState({ isPending, onSubmit }: EmptyStateProps) {
  const { watch } = useCreateVideoRequestFormContext();

  const mandatoryFields = [
    "briefCreatorDetailed.scope",
    "briefCreatorDetailed.creativeGoal",
    "briefCreatorDetailed.script",
  ] as const;

  const filledMandatoryFields = mandatoryFields.reduce(
    (count, field) => count + Number(Boolean(watch(field))),
    0,
  );
  const totalMandatoryFields = 3;

  return (
    <div className="row-span-2 flex h-min flex-col gap-lg rounded-2xl border border-border-secondary bg-bg-secondary p-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-fg-brand-primary" />
          <span className="font-semibold text-text-secondary">
            Magical Brief Creator
          </span>
        </div>
        <div className="flex flex-col">
          <span className="w-full text-sm text-text-secondary">
            Enter your brief details on the left, and enhance the final results
            here.
          </span>
          <span className="text-sm font-bold text-text-brand-secondary">
            To submit, improve your brief with AI!
          </span>
        </div>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button
          isCustomLoading
          isDisabled={
            isPending || filledMandatoryFields !== totalMandatoryFields
          }
          isLoading={isPending}
          loadingLabel="Deploying"
          onPress={onSubmit}
        >
          {isPending ? (
            <>
              <Loader className="animate-spin" />
              Improving...
            </>
          ) : (
            <>
              <WandIcon />
              Improve Brief
            </>
          )}
        </Button>
        <span className="text-sm text-text-secondary">
          {`${filledMandatoryFields}/${totalMandatoryFields} mandatory fields filled`}
        </span>
      </div>
    </div>
  );
}

type MagicalBriefCreatorProps = ReturnType<typeof useAIStream>;

export function MagicalBriefCreator({
  ...aiStreamUtils
}: MagicalBriefCreatorProps) {
  const {
    abortStream,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse,
  } = aiStreamUtils;

  const { getValues } = useCreateVideoRequestFormContext();

  const handleSubmit = useCallback(async () => {
    const formValues = getValues();

    if (formValues) {
      const { briefCreatorDetailed } = formValues;

      sendPrompt(briefCreatorDetailed);
    }
  }, [getValues, sendPrompt]);

  const handleClearResponse = useCallback(() => {
    if (isPending) {
      abortStream();
    } else {
      resetStreamedResponse();
    }
  }, [isPending, abortStream, resetStreamedResponse]);

  const isFetching = isPending && !streamedResponse;
  const isSuccess = streamedResponse;
  const isEmpty = !isPending && !streamedResponse;

  if (isEmpty) {
    return <EmptyState isPending={isPending} onSubmit={handleSubmit} />;
  }

  if (isFetching) {
    return <PendingState />;
  }

  if (isSuccess) {
    return (
      <SuccessState
        handleClearResponse={handleClearResponse}
        isPending={isPending}
        onSubmit={handleSubmit}
        streamedResponse={streamedResponse}
      />
    );
  }

  return null;
}
