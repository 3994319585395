import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Loader } from "lucide-react";

import { Button } from "@/components/button";
import { env } from "@/config/env";

import {
  useCheckNewNotifications,
  useGetNotifications,
  useMarkAllNotificationsAsRead,
} from "./hooks";
import { NotificationItem } from "./NotificationItem";
import { NotificationsLayout } from "./NotificationsLayout";

dayjs.extend(relativeTime);

export function Notifications() {
  const {
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetNotifications();

  const { data: notificationCheck } = useCheckNewNotifications();
  const { mutate: markAllAsRead } = useMarkAllNotificationsAsRead();

  const hasNewNotifications = notificationCheck?.someUnread ?? false;

  const isEmpty =
    !notifications?.pages?.length ||
    notifications.pages[0]?.metadata.totalItems === 0;

  const notificationHeader = !isEmpty && !isLoading && (
    <div className="flex items-center justify-between px-lg py-md">
      <a
        className="text-sm font-semibold text-button-tertiary-fg"
        href={`${env.VITE_BASE_URL}/notifications-v2?unread=false`}
      >
        View All
      </a>
      {hasNewNotifications && (
        <Button
          className="text-sm font-semibold text-button-tertiary-color-fg"
          onPress={() => markAllAsRead()}
          variant="unstyled"
        >
          Mark all as read
        </Button>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <NotificationsLayout
        hasNewNotifications={hasNewNotifications}
        header={notificationHeader}
      >
        <span className="mx-auto py-3xl text-sm font-semibold text-text-primary">
          <Loader className="size-5 animate-spin" />
        </span>
      </NotificationsLayout>
    );
  }

  if (isEmpty) {
    return (
      <NotificationsLayout
        hasNewNotifications={hasNewNotifications}
        header={notificationHeader}
      >
        <span className="mx-auto py-3xl text-sm font-semibold text-text-primary">
          Your notification inbox is empty
        </span>
      </NotificationsLayout>
    );
  }

  return (
    <NotificationsLayout
      hasNewNotifications={hasNewNotifications}
      header={notificationHeader}
    >
      <div className="flex flex-col overflow-y-auto">
        {notifications?.pages.map((page) =>
          page.data.map((notification) => (
            <NotificationItem key={notification.id} {...notification} />
          )),
        )}
        {hasNextPage && (
          <div className="flex items-center justify-center py-lg">
            <Button
              color="gray"
              isDisabled={isFetchingNextPage}
              onPress={() => fetchNextPage()}
              size="md"
              variant="secondary"
            >
              {isFetchingNextPage ? "Loading..." : "See More"}
            </Button>
          </div>
        )}
      </div>
    </NotificationsLayout>
  );
}
