import { BrandsSelect } from "./BrandsSelect";
import { RequestNameInput } from "./RequestNameInput";
import { SelectVideoType } from "./SelectVideoType";

export function IntroSection() {
  return (
    <div className="grid grid-cols-4 gap-x-3xl">
      <RequestNameInput />
      <SelectVideoType />
      <BrandsSelect />
    </div>
  );
}
