import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Button } from "@/components/button";
import { Checkbox, CheckboxField } from "@/components/checkbox";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@/components/dialog";
import { Description } from "@/components/field";
import { Link } from "@/components/link";
import { Modal } from "@/components/modal";
import { env } from "@/config/env";
import apiClient from "@/lib/api/client";

const TOC_LINK = "https://nlc.com/terms-of-service/";
const PRIVACY_POLICY_LINK = "https://nlc.com/privacy-policy/";

type TermsPrivacyAgreementModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export function TermsPrivacyAgreementModal({
  isOpen,
  setIsOpen,
}: TermsPrivacyAgreementModalProps) {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const { isPending: isAcceptingTerms, mutate: acceptTerms } = useMutation({
    mutationFn: async () =>
      await apiClient
        .url("/users/me/agreement")
        .put({
          agreement: true,
        })
        .res(),
    onError: () => {
      setIsTermsAccepted(false);
    },
    onSuccess: () => {
      setIsOpen(false);
      window.location.href = `${env.VITE_BASE_URL}/requests`;
    },
  });

  return (
    <Modal isOpen={isOpen}>
      <Dialog>
        <DialogHeader>Terms and Privacy Agreement</DialogHeader>
        <DialogBody>
          <form
            id="terms-of-service-form"
            onSubmit={(e) => {
              e.preventDefault();
              acceptTerms();
            }}
          >
            <CheckboxField className="max-w-sm">
              <Checkbox
                isSelected={isTermsAccepted}
                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
              >
                I accept to the Terms of Service and Privacy Policy
              </Checkbox>
              <Description>
                Read the{" "}
                <Link
                  className="text-utility-blue-500"
                  href={TOC_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  className="text-utility-blue-500"
                  href={PRIVACY_POLICY_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </Description>
            </CheckboxField>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            form="terms-of-service-form"
            isDisabled={!isTermsAccepted}
            isLoading={isAcceptingTerms}
            type="submit"
          >
            Continue
          </Button>
        </DialogFooter>
      </Dialog>
    </Modal>
  );
}
