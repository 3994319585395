import wretch from "wretch";
import AbortAddon from "wretch/addons/abort";
import QueryStringAddon from "wretch/addons/queryString";

import { env } from "@/config/env";

const BASE_URL = env.VITE_BASE_API_URL;

const authenticatedApi = wretch(`${BASE_URL}/api`)
  .addon(QueryStringAddon)
  .addon(AbortAddon())
  .options({
    credentials: "include",
  })
  .resolve((resolver) =>
    resolver
      .fetchError(() => {
        // TODO: add toast on error
        throw new Error("Failed to connect to server");
      })
      .unauthorized(async () => {
        await handleLogout();
      }),
  )
  .catcherFallback((error) => {
    throw error.json;
  });

export default authenticatedApi;

async function handleLogout() {
  try {
    // Should logout on the PHP side and destroy the session
    await wretch(`${BASE_URL}/logout`)
      .options({ credentials: "include" })
      .post()
      .res();
  } catch (error) {
    console.error("Logout error:", error);
  } finally {
    window.location.href = "/login";
  }
}

export const unauthenticatedApi = wretch(`${BASE_URL}/api`)
  .addon(QueryStringAddon)
  .options({
    credentials: "include",
  })
  .resolve((resolver) =>
    resolver.fetchError(() => {
      // TODO: add toast on error
      throw new Error("Failed to connect to server");
    }),
  )
  .catcherFallback((error) => {
    throw error.json;
  });
