import type {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from "@tanstack/react-query";

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { toast } from "@/components/toast/toast-queue";
import apiClient from "@/lib/api/client";

import type { NotificationCheckResponse, NotificationsResponse } from "./types";

export function useGetNotifications(): UseInfiniteQueryResult<
  InfiniteData<NotificationsResponse>
> {
  return useInfiniteQuery<NotificationsResponse>({
    getNextPageParam: (lastPage: NotificationsResponse) => {
      if (lastPage.metadata.page < lastPage.metadata.totalPages) {
        return lastPage.metadata.page + 1;
      }
    },
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      apiClient
        .url("/notifications")
        .query({ limit: 10, page: pageParam })
        .get()
        .json<NotificationsResponse>(),
    queryKey: ["notifications"],
  });
}

export function useMarkNotificationAsRead(): UseMutationResult<
  NotificationsResponse,
  Error,
  string
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: string) =>
      apiClient
        .url("/notifications/read")
        .patch({ notifications: [notificationId] })
        .json(),
    onMutate: async (notificationId) => {
      queryClient.setQueryData<InfiniteData<NotificationsResponse>>(
        ["notifications"],
        (old) => {
          if (!old) return old;

          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.map((notification) =>
                notification.id === notificationId
                  ? { ...notification, isNew: false }
                  : notification,
              ),
            })),
          };
        },
      );
    },
  });
}

export function useMarkAllNotificationsAsRead(): UseMutationResult<
  NotificationsResponse,
  Error,
  void
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      apiClient
        .url("/notifications/read")
        .patch({ markAll: true, notifications: [] })
        .res(),
    onMutate: async () => {
      queryClient.setQueryData<InfiniteData<NotificationsResponse>>(
        ["notifications"],
        (old) => {
          if (!old) return old;

          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              data: page.data.map((notification) => ({
                ...notification,
                isNew: false,
              })),
            })),
          };
        },
      );
    },
    onSuccess: () => {
      toast.add(
        {
          title: "Notifications marked as Read",
          type: "success",
        },
        { timeout: 5000 },
      );
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });
}

export function useCheckNewNotifications(): UseQueryResult<NotificationCheckResponse> {
  return useQuery({
    queryFn: () =>
      apiClient
        .url("/notifications/check")
        .get()
        .json<NotificationCheckResponse>(),
    queryKey: ["notifications", "check"],
    refetchInterval: 60 * 1000, // 1 minute
    refetchIntervalInBackground: true,
  });
}
