import { X } from "lucide-react";
import { AlertCircle } from "lucide-react";
import { Button } from "react-aria-components";

type AlertProps = {
  description: string;
  onClick?: () => void;
  title: string;
};

export function Alert({ description, onClick, title }: AlertProps) {
  return (
    <div className="flex gap-xl rounded-xl border border-border-brand p-xl">
      <div className="grid size-4xl shrink-0 place-items-center rounded-full bg-bg-brand-secondary">
        <AlertCircle className="text-featured-icon-light-fg-brand" size={16} />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-medium text-text-primary">{title}</span>
        <span className="text-sm text-text-tertiary">{description}</span>
      </div>
      <Button
        className="ml-auto grid size-2xl place-items-center"
        onPress={onClick}
      >
        <X className="text-border-brand-solid" size={12} />
      </Button>
    </div>
  );
}
