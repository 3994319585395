import { AssetsForm } from "./components/AssetsForm";

export function AssetsSection() {
  return (
    <div className="flex flex-col gap-3xl">
      <span className="col-span-2 text-lg font-semibold text-text-primary">
        Assets & Inspirations
      </span>

      <div className="grid grid-cols-2 gap-2xl">
        <AssetsForm field="assets" title="Assets" />
        <AssetsForm field="inspirations" title="Inspirations" />
      </div>
    </div>
  );
}
