import { Controller } from "react-hook-form";

import { FieldError, Input, Label, TextField } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";
import { CollaboratorsSelect } from "./CollaboratorsSelect";
import { PreferredDesignersSelect } from "./PreferredDesignersSelect";

export function TechnicalDetailsSection() {
  const methods = useCreateVideoRequestFormContext();

  return (
    <div className="grid grid-cols-12 gap-x-3xl gap-y-lg">
      <span className="col-span-12 text-lg font-semibold text-text-primary">
        Technical Details
      </span>
      <Controller
        control={methods.control}
        name="creativeDirection"
        render={({ field: { name, onBlur, onChange, value } }) => (
          <Select
            className="col-span-3"
            name={name}
            onBlur={onBlur}
            onSelectionChange={onChange}
            placeholder="Select creative direction"
            selectedKey={value}
          >
            <Label>Creative direction</Label>
            <SelectButton />
            <SelectPopover>
              <SelectListBox>
                <SelectListItem id="Designer has creative freedom">
                  Designer has creative freedom
                </SelectListItem>
                <SelectListItem id="Follow brief exactly">
                  Follow brief exactly
                </SelectListItem>
              </SelectListBox>
            </SelectPopover>
          </Select>
        )}
      />
      <Controller
        control={methods.control}
        name="sizes"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            className="col-span-3"
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <Label requiredHint>Sizes needed</Label>
            <Input placeholder="E.g. 1080x1080, 16x9, etc." ref={ref} />
            <FieldError>{error?.message}</FieldError>
          </TextField>
        )}
      />
      <Controller
        control={methods.control}
        name="lengthOrPlatform"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            className="col-span-3"
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <Label requiredHint>Length or platform</Label>
            <Input
              placeholder="Indicate lenght of video or platform"
              ref={ref}
            />
            <FieldError>{error?.message}</FieldError>
          </TextField>
        )}
      />
      <Controller
        control={methods.control}
        name="style"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            className="col-span-3"
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <Label>Style of video</Label>
            <Input placeholder="Indicate style of video" ref={ref} />
            <FieldError>{error?.message}</FieldError>
          </TextField>
        )}
      />
      <Controller
        control={methods.control}
        name="musicGenre"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            className="col-span-4"
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <Label>Music Genre</Label>
            <Input placeholder="Indicate type of music" ref={ref} />
            <FieldError>{error?.message}</FieldError>
          </TextField>
        )}
      />
      <Controller
        control={methods.control}
        name="fileTypes"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, invalid },
        }) => (
          <TextField
            className="col-span-4"
            isInvalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            validationBehavior="aria"
            value={value}
          >
            <Label requiredHint>File types</Label>
            <Input placeholder="E.g. JPG, PNG, PDF, etc." ref={ref} />
            <FieldError>{error?.message}</FieldError>
          </TextField>
        )}
      />
      <PreferredDesignersSelect />
      <CollaboratorsSelect />
    </div>
  );
}
