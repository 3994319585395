import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";
import { DetailedForm } from "./DetailedForm";
import useAIStream from "./hooks/useAIStream";
import { MagicalBriefCreator } from "./MagicalBriefCreator";
import { SingleInputForm } from "./SingleInputForm";
import { TabsHeader } from "./TabsHeader";

export function BriefCreatorSection() {
  const { watch } = useCreateVideoRequestFormContext();
  const formType = watch("briefCreatorType");

  const aiStreamUtils = useAIStream();

  return (
    <div className="grid grid-cols-2 grid-rows-[min-content_min-content] gap-x-3xl gap-y-lg">
      <TabsHeader isDisabled={Boolean(aiStreamUtils.streamedResponse)} />
      {formType === "detailed" && (
        <>
          <MagicalBriefCreator {...aiStreamUtils} />
          <DetailedForm isDisabled={Boolean(aiStreamUtils.streamedResponse)} />
        </>
      )}
      {formType === "single" && <SingleInputForm />}
    </div>
  );
}
