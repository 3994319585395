import type { ReactNode } from "react";
import type {
  CheckboxGroupProps as RACCheckboxGroupProps,
  CheckboxProps as RACCheckboxProps,
} from "react-aria-components";

import { CheckIcon, MinusIcon } from "lucide-react";
import React from "react";
import {
  Checkbox as RACCheckbox,
  CheckboxGroup as RACCheckboxGroup,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { DescriptionContext, DescriptionProvider } from "../field";
import {
  composeTailwindRenderProps,
  focusOutlineStyle,
  groupBoxStyle,
} from "../utils";

export interface CheckboxGroupProps
  extends Omit<RACCheckboxGroupProps, "children"> {
  children?: ReactNode;
  orientation?: "horizontal" | "vertical";
}

export function CheckboxGroup({
  orientation = "vertical",
  ...props
}: CheckboxGroupProps) {
  return (
    <RACCheckboxGroup
      {...props}
      className={composeTailwindRenderProps(props.className, groupBoxStyle)}
      data-orientation={orientation}
    />
  );
}

export function Checkboxes({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) {
  return (
    <div
      className={twMerge(
        "flex flex-col group-data-[orientation=horizontal]:flex-row group-data-[orientation=horizontal]:flex-wrap",
        "[&_label]:has-[[data-ui=description]]:font-medium",
        className,
      )}
      data-ui="box"
      {...props}
    />
  );
}

export function CheckboxField({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) {
  return (
    <DescriptionProvider>
      <div
        {...props}
        className={twMerge(
          "group flex flex-col",
          "text-text-tertiary font-normal text-sm",
          "[&_[data-ui=description]:not([class*=pe-])]:has-[label[data-position=left]]:ps-[24px]",
          "[&_[data-ui=description]:not([class*=ps-])]:has-[label[data-position=right]]:ps-[24px]",
          "[&_label]:has-[[data-ui=description]]:font-medium",
          className,
        )}
        data-ui="field"
      />
    </DescriptionProvider>
  );
}

interface CheckboxProps extends RACCheckboxProps {
  labelPosition?: "left" | "right";
}

export function Checkbox({
  children,
  labelPosition = "right",
  ...props
}: CheckboxProps) {
  const descriptionContext = React.useContext(DescriptionContext);

  return (
    <RACCheckbox
      {...props}
      aria-describedby={descriptionContext?.["aria-describedby"]}
      className={composeTailwindRenderProps(props.className, [
        "group flex items-center gap-x-md text-text-secondary",
        "group-data-[orientation=horizontal]:text-nowrap",
        "data-[position=left]:flex-row-reverse",
        "data-[position=left]:justify-between",
        "font-medium text-sm",
      ])}
      data-position={labelPosition}
    >
      {(renderProps) => (
        <>
          <div
            className={twMerge([
              "flex flex-shrink-0 items-center justify-center",
              "size-[16px] rounded-xs transition",
              "border border-border-primary",
              renderProps.isInvalid && "border-destructive",
              (renderProps.isSelected || renderProps.isIndeterminate) && [
                "border-bg-brand-solid bg-bg-brand-solid",
              ],
              (renderProps.isSelected || renderProps.isIndeterminate) &&
                renderProps.isDisabled && [
                  "bg-bg-disabled-subtle border-border-primary",
                ],
              renderProps.isFocusVisible && focusOutlineStyle,
            ])}
          >
            {renderProps.isIndeterminate ? (
              <MinusIcon
                className={twMerge([
                  "size-lg text-fg-white",
                  renderProps.isDisabled && "text-fg-disabled-subtle",
                ])}
              />
            ) : renderProps.isSelected ? (
              <CheckIcon
                className={twMerge([
                  "size-lg text-fg-white",
                  renderProps.isDisabled && "text-fg-disabled-subtle",
                ])}
              />
            ) : null}
          </div>

          {typeof children === "function" ? children(renderProps) : children}
        </>
      )}
    </RACCheckbox>
  );
}
