import type { UseFormSetError } from "react-hook-form";

import { useState } from "react";

import type { FileAsset, LinkAsset } from "../../useCreateVideoRequestForm";
import type { LinkFormValues } from "../useLinkForm";

import { useCreateVideoRequestFormContext } from "../../useCreateVideoRequestForm";
import { MAX_FILES } from "../utils/uploadUtils";
import { useFileDelete } from "./useFileDelete";
import { useFileUpload } from "./useFileUpload";
import { useLinkManagement } from "./useLinkManagement";

type AssetField = "assets" | "inspirations";

export function useAssetsSection(
  field: AssetField,
  setLinkFormError: UseFormSetError<LinkFormValues>,
): {
  assets: (FileAsset | LinkAsset)[];
  error: null | string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (awsKey: string) => void;
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
} {
  const methods = useCreateVideoRequestFormContext();
  const [error, setError] = useState<null | string>(null);

  const assets = methods.watch(field);

  const { handleFileUpload } = useFileUpload(field, assets, setError, methods);

  const { handleLinkAdd, handleLinkDelete } = useLinkManagement(
    field,
    assets,
    methods,
    setError,
    setLinkFormError,
  );

  const { handleFileDelete } = useFileDelete(field, assets, setError, methods);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError(null);

    if (!event.target.files) return;

    const uploadedFiles = [...event.target.files];

    if (uploadedFiles.length + assets.length > MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      return;
    }

    handleFileUpload(uploadedFiles);
  };

  return {
    assets,
    error,
    handleFileChange,
    handleFileDelete,
    handleLinkAdd,
    handleLinkDelete,
  };
}
