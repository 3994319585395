import { Controller } from "react-hook-form";

import {
  ComboBox,
  ComboBoxButton,
  ComboBoxGroup,
  ComboBoxInput,
  ComboBoxListBox,
  ComboBoxListItem,
  ComboBoxPopover,
  ComboBoxSection,
} from "@/components/combobox";
import { FieldError, Label } from "@/components/field";
import { Text } from "@/components/text";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";
import groupedVideoTypes from "./consts/groupedVideoTypes.json";

export function SelectVideoType() {
  const { control } = useCreateVideoRequestFormContext();

  return (
    <Controller
      control={control}
      name="videoType"
      render={({
        field: { name, onBlur, onChange, value },
        fieldState: { error, invalid },
      }) => (
        <ComboBox
          allowsEmptyCollection
          isInvalid={invalid}
          menuTrigger="input"
          name={name}
          onBlur={onBlur}
          onSelectionChange={onChange}
          selectedKey={value}
        >
          <Label requiredHint>Video Type</Label>
          <ComboBoxGroup>
            <ComboBoxInput placeholder="Select a request type" />
            <ComboBoxButton />
          </ComboBoxGroup>
          <FieldError>{error?.message}</FieldError>

          <ComboBoxPopover>
            <ComboBoxListBox
              className="max-h-[291px]"
              renderEmptyState={() => (
                <Text className="p-2">No results found</Text>
              )}
            >
              {Object.entries(groupedVideoTypes).map(
                ([subCategoryName, types]) => (
                  <ComboBoxSection
                    key={subCategoryName}
                    title={subCategoryName}
                  >
                    {types.map((type) => (
                      <ComboBoxListItem id={type.id} key={type.name}>
                        {type.name}
                      </ComboBoxListItem>
                    ))}
                  </ComboBoxSection>
                ),
              )}
            </ComboBoxListBox>
          </ComboBoxPopover>
        </ComboBox>
      )}
    />
  );
}
