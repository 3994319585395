import type {
  FieldErrorProps,
  LabelProps,
  InputProps as RACInputProps,
  TextAreaProps as RACTextAreaProps,
  TextFieldProps as RACTextFieldProps,
  TextProps,
} from "react-aria-components";

import React from "react";
import {
  composeRenderProps,
  GroupContext,
  LabelContext,
  FieldError as RACFieldError,
  Input as RACInput,
  Label as RACLabel,
  Text as RACText,
  TextArea as RACTextArea,
  TextField as RACTextField,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { DisplayLevel } from "../utils";

import {
  composeTailwindRenderProps,
  displayLevels,
  focusRingStyle,
  inputFieldStyle,
} from "../utils";

// https://react-spectrum.adobe.com/react-aria/Group.html#advanced-customization
export function LabeledGroup({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const labelId = React.useId();

  return (
    <LabelContext.Provider value={{ elementType: "span", id: labelId }}>
      <GroupContext.Provider value={{ "aria-labelledby": labelId }}>
        <div
          className={twMerge(
            ["[&>[data-ui=label]:first-of-type:not([class*=mb])]:mb-2"],
            className,
          )}
        >
          {children}
        </div>
      </GroupContext.Provider>
    </LabelContext.Provider>
  );
}

export function Label({
  displayLevel = 3,
  requiredHint,
  ...props
}: {
  displayLevel?: DisplayLevel;
  requiredHint?: boolean;
} & LabelProps) {
  return (
    <RACLabel
      {...props}
      className={twMerge(
        "inline-block min-w-max text-pretty text-sm text-text-secondary font-medium",
        displayLevels[displayLevel],
        requiredHint &&
          "after:ms-0.5 after:text-text-error-primary after:content-['*']",
        props.className,
      )}
      data-ui="label"
    />
  );
}

export const DescriptionContext = React.createContext<{
  "aria-describedby"?: string;
} | null>(null);

export function DescriptionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const descriptionId: null | string = React.useId();
  const [descriptionRendered, setDescriptionRendered] = React.useState(true);

  React.useLayoutEffect(() => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    if (!document.getElementById(descriptionId)) {
      setDescriptionRendered(false);
    }
  }, [descriptionId]);

  return (
    <DescriptionContext.Provider
      value={{
        "aria-describedby": descriptionRendered ? descriptionId : undefined,
      }}
    >
      {children}
    </DescriptionContext.Provider>
  );
}

/**
 * RAC will auto associate <RACText slot="description"/> with TextField/NumberField/RadioGroup/CheckboxGroup/DatePicker etc,
 * but not for Switch/Checkbox/Radio and our custom components. We use follow pattern to associate description for
 * Switch/Checkbox/Radio https://react-spectrum.adobe.com/react-aria/Switch.html#advanced-customization
 */
export function Description({ className, ...props }: TextProps) {
  const describedby =
    React.useContext(DescriptionContext)?.["aria-describedby"];

  return describedby ? (
    <span
      {...props}
      className={twMerge(
        "block font-normal text-sm text-text-tertiary",
        className,
      )}
      data-ui="description"
      id={describedby}
    />
  ) : (
    <RACText
      {...props}
      className={twMerge(
        "block font-normal text-sm text-text-tertiary",
        className,
      )}
      data-ui="description"
      slot="description"
    />
  );
}

export function TextField(props: RACTextFieldProps) {
  return (
    <RACTextField
      {...props}
      className={composeTailwindRenderProps(props.className, inputFieldStyle)}
      data-ui="text-field"
    />
  );
}

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "block text-sm font-normal text-text-error-primary mt-sm",
      )}
    />
  );
}

export type InputProps = RACInputProps;
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    return (
      <RACInput
        {...props}
        className={composeRenderProps(
          props.className,
          (className, { isDisabled, isFocused, isInvalid }) =>
            twMerge(
              "w-full text-text-primary rounded-md border outline-none border-border-primary bg-bg-primary",
              "px-[14px] py-[10px]",
              "text-md placeholder:text-text-placeholder",
              isInvalid && "border-border-error",
              isDisabled && "bg-bg-disabled-subtle",
              isFocused && focusRingStyle,
              className,
            ),
        )}
        ref={ref}
      />
    );
  },
);

export type TextAreaProps = RACTextAreaProps;
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    return (
      <RACTextArea
        {...props}
        className={composeRenderProps(
          props.className,
          (className, { isDisabled, isFocused, isInvalid }) =>
            twMerge(
              "w-full text-text-primary rounded-md border bg-inherit outline-none border-border-primary bg-bg-primary",
              "px-[14px] py-[10px]",
              "text-md placeholder:text-text-placeholder",
              isInvalid && "border-border-error",
              isDisabled && "bg-bg-disabled-subtle",
              isFocused && focusRingStyle,
              className,
            ),
        )}
        ref={ref}
      />
    );
  },
);
