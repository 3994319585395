import { ToastQueue } from "@react-stately/toast";

type Position =
  | "bottom-center"
  | "bottom-left"
  | "bottom-right"
  | "top-center"
  | "top-left"
  | "top-right";

type Type = "error" | "success" | "warning";

export type ToastConfig =
  | {
      description?: string;
      position?: Position;
      render?: never;
      title?: string;
      type?: Type;
    }
  | {
      position?: Position;
      render: () => React.ReactNode;
      type?: Type;
    };

export const toast = new ToastQueue<ToastConfig>({
  hasExitAnimation: true,
  maxVisibleToasts: 5,
});
