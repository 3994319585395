import {
  ArrowLeftFromLine,
  ArrowRightFromLine,
  HelpCircleIcon,
} from "lucide-react";

import { Button } from "@/components/button";
import { useUser } from "@/hooks/useUser";

interface HelpSectionProps {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
}

const HELP_CENTER_ROLES = new Set(["admin", "owner", "collaborator"]);
const HELP_CENTER_URL = "https://help.nlc.com/en/";

export function HelpSection({
  isCollapsed,
  onToggleCollapse,
}: HelpSectionProps) {
  const { data: user } = useUser();

  if (!user) return null;

  return (
    <div className="flex flex-col gap-md">
      {HELP_CENTER_ROLES.has(user.user.role_name) && (
        <a
          className="mx-auto flex w-full place-content-center items-center gap-md rounded-sm px-lg py-md text-sm font-semibold text-button-tertiary-fg"
          href={HELP_CENTER_URL}
          rel="noreferrer"
          target="_blank"
        >
          <HelpCircleIcon className="size-2xl text-fg-tertiary" />
          {!isCollapsed && "Help center"}
        </a>
      )}
      <Button
        aria-label="Toggle sidebar"
        className={isCollapsed ? "mx-auto" : "ml-auto"}
        color="gray"
        isIconOnly
        onPress={onToggleCollapse}
        size="sm"
        variant="secondary"
      >
        {isCollapsed ? (
          <ArrowRightFromLine className="size-5 text-fg-tertiary" />
        ) : (
          <ArrowLeftFromLine className="size-5 text-fg-tertiary" />
        )}
      </Button>
    </div>
  );
}
