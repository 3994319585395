import type { ReactNode } from "@tanstack/react-router";

import { Button as RACButton } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { env } from "@/config/env";

import { Tooltip, TooltipTrigger } from "../../tooltip";

interface NavItemProps {
  href: string;
  icon: ReactNode;
  isActive?: boolean;
  isCollapsed: boolean;
  label: string;
}

export function NavItem({
  href,
  icon: Icon,
  isActive,
  isCollapsed,
  label,
}: NavItemProps) {
  const isAbsoluteUrl = /^http(s)?:\/\//i.test(href);
  const finalHref = isAbsoluteUrl ? href : `${env.VITE_BASE_URL}${href}`;

  return (
    <li>
      <TooltipTrigger delay={250}>
        <RACButton className="w-full outline-none">
          <a
            className={twMerge(
              "mx-auto flex h-[48px] items-center gap-md rounded-sm px-lg py-md text-md font-semibold text-text-secondary hover:bg-bg-primary-hover",
              isActive && "bg-bg-primary-hover",
              isCollapsed && "place-content-center w-[48px]",
            )}
            href={finalHref}
            {...(isAbsoluteUrl && {
              rel: "noopener noreferrer",
              target: "_blank",
            })}
          >
            <Icon
              className={twMerge(
                "size-6 text-nav-item-icon-fg",
                isActive && "text-fg-brand-primary",
              )}
            />
            {!isCollapsed && label}
          </a>
        </RACButton>
        {isCollapsed && <Tooltip placement="right">{label}</Tooltip>}
      </TooltipTrigger>
    </li>
  );
}
