import { composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";

export function composeTailwindRenderProps<T>(
  className: ((v: T) => string) | string | undefined,
  tw: Array<string | undefined> | string,
): ((v: T) => string) | string {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

export const focusOutlineStyle = [
  "outline outline-4 outline-bg-brand-solid/25 outline-offset-0",
  "group-data-[readonly]:outline-bg-brand-solid",
];

export const focusRingStyle = [
  "ring-4 ring-border-brand/25 border-border-brand",
  "invalid:border-border-error group-invalid:border-border-error invalid:ring-border-error/25 group-invalid:ring-border-error/25",

  // Readonly style
  "[&[readonly]]:ring-border-brand/25 [&[readonly]]:border-bg-brand-solid/25",
  "[&:has(:is([readonly],[aria-readonly]))]:ring-bg-brand-solid/25 [&:has(:is([readonly],[aria-readonly]))]:border-bg-brand-solid/25",
];

// RAC uses `slot=*`. We use `data-ui=* to avoid potential conflict
export const inputFieldStyle = [
  "group",
  // Label style
  "[&_[data-ui=label]:not([class*=mb-])]:mb-sm",
  "[&_[data-ui=label]:not([class*=mb-]):has(+:is(input,textarea,[data-ui=control]))]:mb-sm",

  // Description style
  "[&>:is(input,[data-ui=control])+[data-ui=description]:not([class*=mt-])]:mt-sm",
  "[&>textarea+[data-ui=description]:not([class*=mt-])]:mt-sm",
  "[&_[data-ui=description]:not([class*=mb-]):has(+:is(input,textarea,[data-ui=control]))]:mb-sm",

  // Error
  // RAC FieldError does not accept data-ui
  "[&>:is(input,textarea,[data-ui=control])+[slot=errorMessage]:not([class*=mt-])]:mt-2",
  "[&:has([data-ui=description]+[slot=errorMessage])_[slot=errorMessage]]:mt-1",
];

export const groupBoxStyle = [
  "group flex flex-col",

  // Group description style
  "[&_[data-ui=description]:not([class*=mt-]):has(+[data-ui=box])]:mt-1",
  "[&_[data-ui=description]:not([class*=mt-]):has(+[data-ui=box])]:mb-4",

  // Group box style
  "[&:not(:has([data-ui=description]+[data-ui=box]))>[data-ui=box]]:mt-3",

  "[&:has(:is([type=checkbox],[type=radio],[role=switch]))_[data-ui=box]:not([class*=gap-])]:gap-y-3",

  //Group label style when field has description
  "[&:has([data-ui=field]>[data-ui=description])>[data-ui=label]]:font-semibold",

  // Box item description inside
  "[&:has(:is([type=checkbox],[type=radio],[role=switch]))_[data-ui=box]:has([data-ui=description]):not([class*=gap-y])]:gap-y-4",

  // Horizontal
  "[&[data-orientation=horizontal]:has(:is([type=checkbox],[type=radio],[role=switch]))_[data-ui=box]:not([class*=gap-x-])]:gap-x-4",
  "[&[data-orientation=horizontal]:has(:is([type=checkbox],[type=radio],[role=switch]))_[data-ui=box]:not([class*=gap-y-])]:gap-y-2",

  // Error
  "[&:has([data-ui=box]+[slot=errorMessage])_[slot=errorMessage]]:mt-2",
];

// Chevron down as select box indicator
export const selectBoxIndicator = [
  "after:pointer-events-none",
  "after:absolute",
  "after:border-gray-500",
  "after:content-['']",
  "after:size-[7px]",
  "after:border-b-[1.5px] after:border-r-[1.5px]",
  "after:bottom-[55%] after:end-3 after:-translate-x-1/2 after:translate-y-1/2 after:rotate-45 rtl:after:translate-x-1.5",
];

export const displayLevels = {
  1: "",
  2: "font-semibold text-lg text-gray-900",
  3: "",
};

export type DisplayLevel = keyof typeof displayLevels;
