import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type RequestFormDirtyState = {
  isDirty: boolean;
  reset: () => void;
  setIsDirty: (isDirty: boolean) => void;
};

export const useIsRequestFormDirty = create<RequestFormDirtyState>()(
  subscribeWithSelector((set) => ({
    isDirty: false,
    reset: () => set({ isDirty: false }),
    setIsDirty: (isDirty) => set({ isDirty }),
  })),
);
