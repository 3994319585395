import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { Controller } from "react-hook-form";

import { FieldError, Label } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";
import { brandsKeys } from "@/hooks/queryKeys";
import { useGetCurrentProjectId } from "@/hooks/useGetCurrentProjectId";
import apiClient from "@/lib/api/client";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";

// TODO: replace with shared types when ready
type Brand = {
  id: number;
  textValue: string;
};

type Response = {
  brands: {
    id: number;
    name: string;
  }[];
};
type MappedResponse = Brand[];

function useGetBrands(projectId?: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    enabled: !!projectId,
    queryFn: async () =>
      await apiClient.url(`/brands?projectId=${projectId}`).get().json(),
    queryKey: brandsKeys.brandsByProjectId(projectId),
    select: (data) =>
      data.brands.map(({ id, name }) => ({ id: Number(id), textValue: name })),
  });
}

export function BrandsSelect() {
  const { control } = useCreateVideoRequestFormContext();
  const projectId = useGetCurrentProjectId();
  const { data: brands, isLoading } = useGetBrands(projectId);

  const placeholder = isLoading
    ? "Loading..."
    : brands?.length === 0
      ? "No results"
      : "Select a brand";

  return (
    <Controller
      control={control}
      name="brand"
      render={({
        field: { name, onBlur, onChange, value },
        fieldState: { error, invalid },
      }) => (
        <Select
          isInvalid={invalid}
          name={name}
          onBlur={onBlur}
          onSelectionChange={onChange}
          placeholder={placeholder}
          selectedKey={value}
        >
          <Label>Choose a brand</Label>
          <SelectButton />
          <FieldError>{error?.message}</FieldError>

          <SelectPopover>
            <SelectListBox items={brands}>
              {(item) => (
                <SelectListItem id={item.id}>{item.textValue}</SelectListItem>
              )}
            </SelectListBox>
          </SelectPopover>
        </Select>
      )}
    />
  );
}
