import type {
  GroupProps,
  ComboBoxProps as RACComboBoxProps,
} from "react-aria-components";

import React from "react";
import {
  ComboBoxStateContext,
  Group,
  Keyboard,
  ComboBox as RACComboBox,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { ButtonWithoutAsChildProps } from "../button";

import { Button } from "../button";
import { ChevronDownIcon, XIcon } from "../icons";
import { composeTailwindRenderProps, inputFieldStyle } from "../utils";

export function ComboBox(props: RACComboBoxProps<object>) {
  return (
    <RACComboBox
      {...props}
      className={composeTailwindRenderProps(props.className, [
        "w-full",
        ...inputFieldStyle,
      ])}
      data-ui="comboBox"
    />
  );
}

export function ComboBoxGroup(props: GroupProps) {
  return (
    <Group
      data-ui="control"
      {...props}
      className={composeTailwindRenderProps(props.className, [
        "isolate",
        "grid",
        "grid-cols-[36px_1fr_minmax(40px,max-content)_minmax(40px,max-content)]",
        "sm:grid-cols-[36px_1fr_minmax(36px,max-content)_minmax(36px,max-content)]",
        "items-center",

        // Icon
        "sm:[&>[data-ui=icon]:has(+input)]:size-4",
        "[&>[data-ui=icon]:has(+input)]:size-5",
        "[&>[data-ui=icon]:has(+input)]:row-start-1",
        "[&>[data-ui=icon]:has(+input)]:col-start-1",
        "[&>[data-ui=icon]:has(+input)]:place-self-center",
        "[&>[data-ui=icon]:has(+input)]:text-muted",

        // Input
        "[&>input]:row-start-1",
        "[&>input]:col-span-full",
        "[&>input:not([class*=pe-])]:pe-10",
        "sm:[&>input:not([class*=pe-])]:pe-9",

        "[&>input:has(+[data-ui=clear]:not(:last-of-type))]:pe-20",
        "sm:[&>input:has(+[data-ui=clear]:not(:last-of-type))]:pe-16",

        "[&:has([data-ui=icon]+input)>input]:ps-10",
        "sm:[&:has([data-ui=icon]+input)>input]:ps-8",

        // Trigger button
        "[&>[data-ui=trigger]]:row-start-1",
        "[&>[data-ui=trigger]]:-col-end-1",
        "[&>[data-ui=trigger]]:place-self-center",

        // Clear button
        "[&>[data-ui=clear]]:row-start-1",
        "[&>[data-ui=clear]]:-col-end-2",
        "[&>[data-ui=clear]]:justify-self-end",
        "[&>[data-ui=clear]:last-of-type]:-col-end-1",
        "[&>[data-ui=clear]:last-of-type]:place-self-center",
      ])}
    />
  );
}

export function ComboBoxButton() {
  return (
    <Button data-ui="trigger" isIconOnly size="sm" variant="plain">
      <ChevronDownIcon className="text-text-quaternary" strokeWidth={2} />
    </Button>
  );
}

export function ComboBoxClearButton({
  onPress,
}: {
  onPress?: ButtonWithoutAsChildProps["onPress"];
}) {
  const state = React.useContext(ComboBoxStateContext);

  return (
    <Button
      className={twMerge(state?.inputValue ? "visible" : "invisible")}
      data-ui="clear"
      isIconOnly
      onPress={(e) => {
        state?.setSelectedKey(null);
        onPress?.(e);
      }}
      size="sm"
      slot={null}
      variant="tertiary"
    >
      <XIcon aria-label="Clear" />
    </Button>
  );
}

export function CommandButton() {
  return (
    <>
      <Button
        className="me-1 flex font-normal peer-data-[focused=true]:hidden"
        data-ui="trigger"
        size="sm"
        variant="tertiary"
      >
        <Keyboard className="font-sans">⌘K</Keyboard>
      </Button>

      <Keyboard
        className="me-1 hidden rounded-lg border p-1 text-xs peer-data-[focused=true]:flex ltr:ms-1"
        data-ui="trigger"
      >
        ESC
      </Keyboard>
    </>
  );
}

export { Input as ComboBoxInput } from "../field";
export {
  SelectListBox as ComboBoxListBox,
  SelectListItem as ComboBoxListItem,
  SelectListItemLabel as ComboBoxListItemLabel,
  SelectPopover as ComboBoxPopover,
  SelectSection as ComboBoxSection,
} from "../select";
