import { useRef } from "react";

import { Label } from "@/components/field";

import { SizeAlert } from "../../../../shared/components/SizeAlert";
import { useAssetsSection } from "../hooks/useAssetsSection";
import { useLinkForm } from "../useLinkForm";
import { ALLOWED_EXTENSIONS, isFileAsset } from "../utils/uploadUtils";
import { InsertLinkForm } from "./InsertLinkForm";
import { UploadAssetsInput } from "./UploadAssetsInput";
import { UploadedAsset } from "./UploadedAsset";
import { UploadedLink } from "./UploadedLink";

type AssetsFormProps = {
  field: "assets" | "inspirations";
  title: string;
};

export function AssetsForm({ field, title }: AssetsFormProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { control, handleSubmit, setError: setLinkFormError } = useLinkForm();

  const {
    assets,
    error,
    handleFileChange,
    handleFileDelete,
    handleLinkAdd,
    handleLinkDelete,
  } = useAssetsSection(field, setLinkFormError);

  return (
    <div className="rounded-md bg-bg-secondary px-3xl py-2xl">
      <div className="flex flex-col gap-sm text-text-secondary">
        <span>{title}</span>
        <div className="flex flex-col gap-xl">
          <button
            className="gap-xs rounded-xl border border-border-secondary bg-bg-primary px-3xl py-xl"
            onClick={() => fileInputRef.current?.click()}
            type="button"
          >
            <UploadAssetsInput />
            <input
              accept={ALLOWED_EXTENSIONS.join(",")}
              className="hidden"
              multiple
              onChange={(e) => {
                handleFileChange(e);
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
              }}
              ref={fileInputRef}
              type="file"
            />
          </button>
          {error && (
            <span className="text-sm text-text-error-primary">{error}</span>
          )}
          <SizeAlert />
          <InsertLinkForm
            control={control}
            onSubmit={handleSubmit(handleLinkAdd)}
          />
          {assets.length > 0 ? (
            <div className="flex flex-col gap-sm">
              <Label>Uploaded Links & Files</Label>
              <div className="flex flex-col gap-lg">
                {assets.map((asset) =>
                  isFileAsset(asset) ? (
                    <UploadedAsset
                      file={asset}
                      key={asset.id}
                      onDelete={handleFileDelete}
                    />
                  ) : (
                    <UploadedLink
                      key={asset.id}
                      link={asset}
                      onDelete={handleLinkDelete}
                    />
                  ),
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
