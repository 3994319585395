import { useState } from "react";
import { Controller } from "react-hook-form";

import { Button } from "@/components/button";
import { Checkbox, CheckboxField } from "@/components/checkbox";
import {
  Description,
  FieldError,
  Input,
  Label,
  TextField,
} from "@/components/field";
import { env } from "@/config/env";
import { useTheme } from "@/hooks/useTheme";

import type { LoginFormData } from "./useLoginForm";

import { AuthContainer } from "../components/AuthContainer";
import { PRIVACY_POLICY_LINK, SIGNUP_LINK, TOC_LINK } from "./consts";
import { TermsPrivacyAgreementModal } from "./TermsPrivacyAgreementModal";
import { transformLoginData, useLoginForm } from "./useLoginForm";
import { useLoginMutation } from "./useLoginMutation";

export function LoginForm() {
  useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const { control, handleSubmit, setError } = useLoginForm();
  const { isPending, mutate } = useLoginMutation();

  function onSubmit(data: LoginFormData) {
    const transformedData = transformLoginData(data);

    mutate(transformedData, {
      onError: (error) => {
        setError("email", { message: " ", type: "manual" });
        setError("password", {
          message: error.message,
          type: "manual",
        });
      },
      onSuccess: (data) => {
        if (data.agreement) {
          window.location.href = `${env.VITE_BASE_URL}/requests`;
        } else {
          setIsOpen(true);
        }
      },
    });
  }

  return (
    <AuthContainer
      description="Please enter your login details below"
      footer={
        <>
          {/* TODO: create a Link component if possible */}
          <a
            className="text-text-secondary underline underline-offset-2"
            href={TOC_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of use
          </a>
          <a
            className="text-text-secondary underline underline-offset-2"
            href={PRIVACY_POLICY_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy policy
          </a>
        </>
      }
      title="Platform login"
    >
      <TermsPrivacyAgreementModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <form className="flex flex-col gap-3xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-xl">
          <Controller
            control={control}
            name="email"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                isInvalid={invalid}
                isRequired
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label>Email</Label>
                <Input placeholder="Enter your email" ref={ref} />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
            rules={{ required: "Email is required." }}
          />
          <Controller
            control={control}
            name="password"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                isInvalid={invalid}
                isRequired
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label>Password</Label>
                <Input
                  placeholder="Enter your password"
                  ref={ref}
                  type="password"
                />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
            rules={{ required: "Password is required." }}
          />
        </div>
        <div className="flex justify-between">
          <Controller
            control={control}
            name="remember"
            render={({ field }) => (
              <CheckboxField className="max-w-sm">
                <Checkbox onChange={field.onChange}>Remember me</Checkbox>
                <Description>Save login for 30 days</Description>
              </CheckboxField>
            )}
          />

          <a
            className="h-min text-sm font-semibold text-utility-blue-500"
            href={`${env.VITE_BASE_URL}/forgot-password`}
          >
            Forgot password?
          </a>
        </div>
        <Button isDisabled={isPending} type="submit">
          {isPending ? "Signing in..." : "Sign in"}
        </Button>
        <span className="mx-auto inline-block text-sm font-medium text-text-secondary">
          Don’t have an account?{" "}
          <a
            className="h-min text-sm font-semibold text-utility-blue-500"
            href={SIGNUP_LINK}
          >
            Sign up
          </a>
        </span>
      </form>
    </AuthContainer>
  );
}
