import type { WretchResponse } from "wretch";

import apiClient from "@/lib/api/client";

import type { DetailedFormData } from "../schemas";

export async function createOutputEnhancement(
  data: DetailedFormData,
  abortController: AbortController,
): Promise<WretchResponse> {
  return apiClient
    .url("/brief-creator/output-enhancement")
    .signal(abortController)
    .query({ detailed: true, stream: true, type: "graphic" })
    .post({ prompt: data })
    .res();
}
