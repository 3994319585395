import { twMerge } from "tailwind-merge";

type DotVariantProps = {
  variant: "dot";
};

type NumericVariantProps = {
  inlined?: boolean;
  value: number;
  variant: "numeric";
};

export type NotificationBadgeProps = (DotVariantProps | NumericVariantProps) &
  JSX.IntrinsicElements["span"];

export function NotificationBadge({
  "aria-label": ariaLabel,
  className,
  ...props
}: NotificationBadgeProps) {
  if (props.variant === "dot") {
    return (
      <>
        <span
          aria-hidden
          className={twMerge(
            "absolute right-1 top-1 flex size-2 rounded-full bg-fg-success-secondary",
            className,
          )}
          {...props}
        />
        {ariaLabel && (
          <span className="sr-only" role="status">
            {ariaLabel}
          </span>
        )}
      </>
    );
  }

  const { inlined, value: _value, variant: _variant, ...rest } = props;

  return (
    <>
      <span
        aria-hidden
        className={twMerge([
          inlined
            ? ""
            : "absolute right-0 top-0 -translate-y-1.5 translate-x-1",
          " flex h-4  items-center justify-center rounded-full bg-fg-success-secondary text-[0.65rem] text-white text-white",
          props.value > 0 ? (props.value > 9 ? "w-5" : "w-4") : "hidden",
          className,
        ])}
        {...rest}
      >
        {Math.min(props.value, 9)}
        {props.value > 9 ? <span className="pb-0.5">+</span> : null}
      </span>

      {ariaLabel && (
        <span className="sr-only" role="status">
          {ariaLabel}
        </span>
      )}
    </>
  );
}
