import { ExternalLink, Link2, Trash2 } from "lucide-react";

import { Button } from "@/components/button";

import type { LinkAsset } from "../../useCreateGraphicRequestForm";

type UploadedLinkProps = {
  link: LinkAsset;
  onDelete: (linkId: string) => void;
};

export function UploadedLink({ link, onDelete }: UploadedLinkProps) {
  return (
    <div className="flex items-center justify-between gap-xs rounded-xl border border-border-secondary bg-bg-primary px-xl py-md">
      <div className="flex items-center gap-md overflow-hidden">
        <ExternalLink className="shrink-0 text-fg-quinary" size={16} />
        <a
          className="truncate text-sm font-medium text-utility-blue-700 hover:underline"
          href={link.url}
          rel="noreferrer"
          target="_blank"
          title={link.url}
        >
          {link.url}
        </a>
      </div>
      <div className="flex gap-xs">
        <Button
          color="gray"
          isIconOnly
          onPress={() => onDelete(link.id)}
          size="sm"
          variant="tertiary"
        >
          <Trash2 className="text-button-tertiary-fg" />
        </Button>

        <Button asChild color="gray" isIconOnly size="sm" variant="tertiary">
          <a href={link.url} rel="noreferrer" target="_blank">
            <Link2 className="text-button-tertiary-color-fg" />
          </a>
        </Button>
      </div>
    </div>
  );
}
