import type {
  ButtonProps,
  DialogProps as RACDialogProps,
} from "react-aria-components";

import { XIcon } from "lucide-react";
import React from "react";
import {
  Button,
  OverlayTriggerStateContext,
  Dialog as RACDialog,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { HeadingProps } from "../heading";

import { Heading } from "../heading";
import { Text } from "../text";
import { composeTailwindRenderProps } from "../utils";
export { DialogTrigger } from "react-aria-components";

export interface DialogProps extends RACDialogProps {
  alert?: boolean;
}

export function Dialog({ alert = false, role, ...props }: DialogProps) {
  return (
    <RACDialog
      {...props}
      className={twMerge(
        "relative flex max-h-[inherit] flex-col overflow-hidden outline-none",
        props.className,
      )}
      role={(role ?? alert) ? "alertdialog" : "dialog"}
    />
  );
}

type DialogHeaderProps = HeadingProps;

export function DialogHeader({ className, ...props }: DialogHeaderProps) {
  const headerRef = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    const header = headerRef.current;

    if (!header) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        header.parentElement?.style.setProperty(
          "--dialog-header-height",
          `${entry.target.clientHeight}px`,
        );
      }
    });

    observer.observe(header);

    return () => {
      observer.unobserve(header);
    };
  }, []);

  return (
    <DialogTitle
      {...props}
      className={twMerge("px-[24px] pt-[24px] text-text-primary", className)}
      ref={headerRef}
    />
  );
}

export function DialogBody(props: JSX.IntrinsicElements["div"]) {
  const { children, className, ...rest } = props;

  return (
    <div
      className={twMerge(
        "flex max-h-[calc(var(--visual-viewport-height)-var(--visual-viewport-vertical-padding)-var(--dialog-header-height,0px)-var(--dialog-footer-height,0px))] flex-1 flex-col gap-2 overflow-auto px-[24px] pt-[24px]",
        className,
      )}
      {...rest}
    >
      {typeof children === "string" ? <Text>{children}</Text> : children}
    </div>
  );
}

export function DialogFooter({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) {
  const footerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const footer = footerRef.current;

    if (!footer) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        footer.parentElement?.style.setProperty(
          "--dialog-footer-height",
          `${entry.target.clientHeight}px`,
        );
      }
    });

    observer.observe(footer);

    return () => {
      observer.unobserve(footer);
    };
  }, []);

  return (
    <div
      {...props}
      className={twMerge(
        "mt-auto flex flex-col flex-col-reverse justify-end gap-3 p-6 sm:flex-row",
        className,
      )}
      ref={footerRef}
    />
  );
}

export function DialogCloseButton(props: ButtonProps) {
  const state = React.useContext(OverlayTriggerStateContext)!;

  const { "aria-label": ariaLabel, className, onPress, ...restProps } = props;

  return (
    <Button
      {...restProps}
      className={composeTailwindRenderProps(
        className,
        "absolute end-[12px] top-[12px] p-[10px] text-fg-quinary",
      )}
      onPress={(e) => {
        state.close();
        onPress?.(e);
      }}
    >
      <XIcon aria-label={ariaLabel ?? "Close"} size={24} />
    </Button>
  );
}

export const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  DialogHeaderProps
>(function DialogTitle({ level = 2, ...props }, ref) {
  return <Heading {...props} level={level} ref={ref} slot="title" />;
});
