import { useUser } from "@/hooks/useUser";

export function CurrentPlan() {
  const { currentProject, data } = useUser();

  if (Number(data?.projects.length) > 0 && currentProject) {
    return (
      <div className="m-xl flex flex-col gap-md rounded-md border border-border-primary px-xl py-lg">
        <span className="text-xs font-medium uppercase text-text-quaternary">
          Current plan
        </span>
        <div className="flex flex-col gap-md">
          <div className="flex flex-col gap-xs">
            <span className="text-xs font-semibold uppercase text-text-secondary">
              {currentProject.product}
            </span>
            <div className="flex flex-wrap gap-sm">
              <span className="rounded-full border border-utility-brand-200 bg-utility-brand-50 px-md py-xxs text-xs font-medium capitalize text-utility-brand-700">
                {currentProject.billing_interval}
              </span>
              {currentProject.all_credits > 0 && (
                <span className="rounded-full border border-utility-gray-200 bg-utility-gray-50 px-md py-xxs text-xs font-medium text-utility-gray-700">
                  {`${currentProject.available_credits}/${currentProject.all_credits} credits`}
                </span>
              )}
            </div>
          </div>
          {data?.user.role_name === "owner" && (
            <a
              className="text-sm font-semibold text-button-tertiary-color-fg"
              href="https://nlc.com/manage-my-account/"
              rel="noreferrer"
              target="_blank"
            >
              Manage plan
            </a>
          )}
        </div>
      </div>
    );
  }

  return null;
}
