import { useMutation } from "@tanstack/react-query";
import { useRef } from "react";

import type { DetailedFormData } from "../schemas";

import { useCreateVideoRequestFormContext } from "../../useCreateVideoRequestForm";
import { createOutputEnhancement } from "../api/createOutputEnhancement";

function useAIStream() {
  const methods = useCreateVideoRequestFormContext();
  const abortControllerRef = useRef<AbortController | null>(null);

  const { error, isError, isPending, mutate } = useMutation({
    mutationFn: async ({ data }: { data: DetailedFormData }) => {
      methods.resetField("briefCreatorDetailed.finalBriefHTML");
      abortControllerRef.current = new AbortController();

      const response = await createOutputEnhancement(
        data,
        abortControllerRef.current,
      );

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (reader) {
        try {
          while (true) {
            const { done, value } = await reader.read();

            if (done) break;
            const chunk = decoder.decode(value, { stream: true });

            methods.setValue(
              "briefCreatorDetailed.finalBriefHTML",
              (methods.getValues("briefCreatorDetailed.finalBriefHTML") || "") +
                chunk,
            );
          }
        } finally {
          reader.releaseLock();
        }
      }

      return response;
    },
    mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
  });

  function resetStreamedResponse() {
    methods.resetField("briefCreatorDetailed.finalBriefHTML");
  }

  function sendPrompt(data: DetailedFormData) {
    resetStreamedResponse();
    mutate({ data });
  }

  function abortStream() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  return {
    abortStream,
    error,
    isError,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse: methods.watch("briefCreatorDetailed.finalBriefHTML"),
  };
}

export default useAIStream;
