import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Eye, Loader2 } from "lucide-react";

import type { UserSchema } from "@/hooks/useUser";

import { AccessibleIcon } from "@/components/accessible-icon";
import { MenuItem, MenuItemLabel, MenuSeparator } from "@/components/menu";
import { toast } from "@/components/toast/toast-queue";
import { useIsRequestFormDirty } from "@/hooks/useIsRequestFormDirty";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";

type ProjectItemProps = {
  isCurrentProject: boolean;
  isLoading: boolean;
  onSelect: (projectId: number) => void;
  project: UserSchema["projects"][number];
};

function ProjectItem({
  isCurrentProject,
  isLoading,
  onSelect,
  project,
}: ProjectItemProps) {
  return (
    <MenuItem key={project.id} onAction={() => onSelect(project.id)}>
      <ProjectIcon isCurrentProject={isCurrentProject} isLoading={isLoading} />
      <MenuItemLabel>{project.name}</MenuItemLabel>
    </MenuItem>
  );
}

type ProjectIconProps = {
  isCurrentProject: boolean;
  isLoading: boolean;
};

function ProjectIcon({ isCurrentProject, isLoading }: ProjectIconProps) {
  if (isLoading) {
    return (
      <AccessibleIcon>
        <Loader2 className="animate-spin" />
      </AccessibleIcon>
    );
  }

  if (isCurrentProject) {
    return (
      <AccessibleIcon>
        <Eye />
      </AccessibleIcon>
    );
  }

  return null;
}

function useProjectMutation() {
  const queryClient = useQueryClient();
  const isDirty = useIsRequestFormDirty((state) => state.isDirty);
  const reset = useIsRequestFormDirty((state) => state.reset);

  const mutation = useMutation<unknown, Error, number>({
    mutationFn: (projectId) =>
      apiClient.url("/users/me").patch({ currentProjectId: projectId }).res(),
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onMutate: async (newProjectId) => {
      await queryClient.cancelQueries({ queryKey: ["user"] });

      queryClient.setQueryData<UserSchema>(["user"], (old) => {
        if (!old) return old;

        return {
          ...old,
          currentProjectId: newProjectId,
        };
      });
    },
    onSuccess: () => {
      toast.add(
        {
          title: "Project marked as current project",
          type: "success",
        },
        { timeout: 5000 },
      );
    },
  });

  const handleProjectChange = async (projectId: number) => {
    if (isDirty) {
      const confirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to switch projects? Your changes will be lost.",
      );

      if (confirmed) {
        reset();
        // need to await because of beforeunload event
        await mutation.mutateAsync(projectId);
        window.location.href = "/requests/pick-type";
      }
    } else {
      mutation.mutate(projectId);
    }
  };

  return {
    handleProjectChange,
    isPending: mutation.isPending,
    variables: mutation.variables,
  };
}

export function ProjectsMenu() {
  const { currentProject, data: user } = useUser();
  const { handleProjectChange, isPending, variables } = useProjectMutation();

  if (!user?.projects || user.projects.length <= 1) {
    return null;
  }

  return (
    <>
      {user.projects.map((project) => (
        <ProjectItem
          isCurrentProject={project.id === currentProject?.id}
          isLoading={isPending && variables === project.id}
          key={project.id}
          onSelect={handleProjectChange}
          project={project}
        />
      ))}
      <MenuSeparator />
    </>
  );
}
