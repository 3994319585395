import type {
  AriaToastProps,
  AriaToastRegionProps,
  ToastAria,
} from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import type { ButtonProps as AriaButtonProps } from "react-aria-components";

import { useToast, useToastRegion } from "@react-aria/toast";
import { useToastQueue } from "@react-stately/toast";
import { AlertCircle, CheckCircle2, X } from "lucide-react";
import React from "react";
import { Button as RACButton } from "react-aria-components";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

import type { ToastConfig } from "./toast-queue";

import { toast } from "./toast-queue";
interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<ToastConfig>;
}

interface ToastProps extends AriaToastProps<ToastConfig> {
  state: ToastState<ToastConfig>;
}

function ToastRegion({ state, ...props }: ToastRegionProps) {
  const ref = React.useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  const position =
    state.visibleToasts.at(-1)?.content.position ?? "bottom-left";

  let className = "bottom-6 right-6 anim";

  switch (position) {
    case "bottom-center": {
      className = "bottom-6 left-1/2 -translate-x-1/2";
      break;
    }
    case "bottom-left": {
      className = "bottom-6 left-6";
      break;
    }
    case "top-center": {
      className = "top-6 left-1/2 -translate-x-1/2";
      break;
    }
    case "top-left": {
      className = "top-6 left-6 ";
      break;
    }
    case "top-right": {
      className = "top-6 right-6";
      break;
    }

    default: {
      break;
    }
  }

  return (
    <div
      {...regionProps}
      className={twMerge(
        "toast-region fixed isolate z-50 flex flex-col gap-2 outline-none",
        className,
      )}
      ref={ref}
    >
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} state={state} toast={toast} />
      ))}
    </div>
  );
}

export function GlobalToastRegion(props: AriaToastRegionProps) {
  const state = useToastQueue<ToastConfig>(toast);

  return state.visibleToasts.length > 0
    ? createPortal(<ToastRegion {...props} state={state} />, document.body)
    : null;
}

function Toast({ state, ...props }: ToastProps) {
  const ref = React.useRef(null);
  const {
    closeButtonProps,
    descriptionProps,
    titleProps,
    toastProps,
  }: {
    closeButtonProps: Omit<AriaButtonProps, "children">;
  } & Omit<ToastAria, "closeButtonProps"> = useToast(props, state, ref);

  let animationClassName = "";
  const position = props.toast.content.position ?? "bottom-left";

  switch (position) {
    case "bottom-center":
    case "top-center": {
      animationClassName =
        props.toast.animation === "entering"
          ? "duration-200 slide-in-from-top animate-in ease-out"
          : props.toast.animation === "exiting"
            ? "duration-200 slide-out-to-top animate-out ease-in"
            : "";
      break;
    }
    case "bottom-left":
    case "top-left": {
      animationClassName =
        props.toast.animation === "entering"
          ? "duration-200 slide-in-from-left animate-in ease-out"
          : props.toast.animation === "exiting"
            ? "duration-200 slide-out-to-left animate-out ease-in"
            : "";
      break;
    }
    case "bottom-right":
    case "top-right": {
      animationClassName =
        props.toast.animation === "entering"
          ? "duration-200 slide-in-from-right animate-in ease-out"
          : props.toast.animation === "exiting"
            ? "duration-200 slide-out-to-right animate-out ease-in"
            : "";
      break;
    }
    default: {
      break;
    }
  }

  const type = props.toast.content.type;

  return (
    <div
      {...toastProps}
      className="relative flex flex-1 rounded-lg bg-bg-primary outline-none"
      ref={ref}
    >
      <div
        className={twMerge(
          "toast flex w-[min(85vw,504px)] gap-1 rounded-lg border p-[16px] transition shadow-lg",
          "border border-border-primary",
          animationClassName,
        )}
        onAnimationEnd={() => {
          if (props.toast.animation === "exiting") {
            state.remove(props.toast.key);
          }
        }}
      >
        {props.toast.content.render ? (
          props.toast.content.render()
        ) : (
          <>
            <div className="flex flex-1 items-center gap-xl self-center pr-xl">
              {type === "error" && (
                <div className="relative flex size-2xl items-center justify-center self-start">
                  <div className="absolute size-[38px] rounded-full border-2 border-utility-error-100 opacity-20" />
                  <div className="absolute size-[28px] rounded-full border-2 border-utility-error-200 opacity-30" />
                  <AlertCircle
                    className="z-10 text-utility-error-500"
                    size={20}
                  />
                </div>
              )}

              {type === "warning" && (
                <div className="flex size-2xl items-center justify-center self-start">
                  <div className="absolute size-[38px] rounded-full border-2 border-utility-warning-100 opacity-20" />
                  <div className="absolute size-[28px] rounded-full border-2 border-utility-warning-200 opacity-30" />
                  <AlertCircle
                    className="z-10 text-utility-warning-500"
                    size={20}
                  />
                </div>
              )}

              {type === "success" && (
                <div className="flex size-2xl items-center justify-center self-start">
                  <div className="absolute size-[38px] rounded-full border-2 border-utility-success-100 opacity-20" />
                  <div className="absolute size-[28px] rounded-full border-2 border-utility-success-200 opacity-30" />
                  <CheckCircle2
                    className="z-10 text-utility-success-500"
                    size={20}
                  />
                </div>
              )}

              <div className="flex flex-1 flex-col gap-xs text-sm">
                {props.toast.content.title ? (
                  <div
                    {...titleProps}
                    className="font-semibold text-fg-primary"
                  >
                    {props.toast.content.title}
                  </div>
                ) : null}

                {props.toast.content.description ? (
                  <div className="text-fg-secondary" {...descriptionProps}>
                    {props.toast.content.description}
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}

        <RACButton
          {...closeButtonProps}
          className={twMerge(
            "absolute top-[16px] right-[16px] text-fg-quinary size-[20px] outline-none",
          )}
        >
          <X aria-label="Close" size={20} />
        </RACButton>
      </div>
    </div>
  );
}
