import { Button, TooltipTrigger } from "react-aria-components";

import type { TooltipProps } from "@/components/tooltip";

import { Label } from "@/components/field";
import { Tooltip } from "@/components/tooltip";

import QuestionMarkIcon from "./icons/question-mark.svg?react";

export function EditorLabel({
  focusEditor,
  isRequired,
  label,
  tooltipProps,
}: {
  focusEditor: () => void;
  isRequired?: boolean;
  label?: string;
  tooltipProps?: TooltipProps;
}) {
  return (
    <div className="mb-xs flex items-center justify-between">
      <Label onClick={focusEditor} requiredHint={isRequired}>
        {label}
      </Label>
      {tooltipProps && (
        <TooltipTrigger delay={0}>
          <Button
            aria-label="Show tooltip"
            className="cursor-default outline-none"
          >
            <QuestionMarkIcon className="size-xl text-fg-quinary transition-all duration-300 hover:text-fg-quinary" />
          </Button>
          <Tooltip offset={0} {...tooltipProps} />
        </TooltipTrigger>
      )}
    </div>
  );
}
