import type {
  GroupProps,
  SwitchProps as RACSwitchProps,
} from "react-aria-components";

import React from "react";
import { Group, Switch as RACSwitch } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import {
  DescriptionContext,
  DescriptionProvider,
  LabeledGroup,
} from "../field";
import {
  composeTailwindRenderProps,
  focusOutlineStyle,
  groupBoxStyle,
} from "../utils";

export function SwitchGroup({ className, ...props }: GroupProps) {
  return (
    <LabeledGroup>
      <Group
        {...props}
        className={composeTailwindRenderProps(className, groupBoxStyle)}
      ></Group>
    </LabeledGroup>
  );
}

export function Switches({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) {
  return (
    <div
      className={twMerge(
        "flex flex-col",
        // When any switch item has description, apply all `font-medium` to all switch item labels
        "[&_label]:has-[[data-ui=description]]:font-medium",
        className,
      )}
      data-ui="box"
      {...props}
    />
  );
}

export function SwitchField({
  className,
  ...props
}: JSX.IntrinsicElements["div"]) {
  return (
    <DescriptionProvider>
      <div
        {...props}
        className={twMerge(
          "group flex flex-col gap-y-1",
          "[&_[data-ui=description]:not([class*=pe-])]:has-[label[data-position=left]]:pe-[calc(theme(width.8)+16px)]",
          "[&_[data-ui=description]:not([class*=ps-])]:has-[label[data-position=right]]:ps-[calc(theme(width.8)+12px)]",
          "[&_label]:has-[[data-ui=description]]:font-medium",
          "[&_[data-ui=description]]:has-[label[data-disabled]]:opacity-50",
          className,
        )}
        data-ui="field"
      />
    </DescriptionProvider>
  );
}

interface SwitchProps extends RACSwitchProps {
  labelPosition?: "left" | "right";
}

export function Switch({
  children,
  labelPosition = "right",
  ...props
}: SwitchProps) {
  const descriptionContext = React.useContext(DescriptionContext);

  return (
    <RACSwitch
      {...props}
      aria-describedby={descriptionContext?.["aria-describedby"]}
      className={composeTailwindRenderProps(props.className, [
        "group flex items-center gap-x-3",
        "data-[position=left]:flex-row-reverse",
        "data-[position=left]:justify-between",
        "text-text-secondary text-sm",
        "disabled:opacity-50",
        "cursor-pointer",
      ])}
      data-position={labelPosition}
    >
      {(renderProps) => (
        <>
          <div
            className={twMerge(
              "flex h-[20px] w-[36px] bg-bg-tertiary hover:bg-bg-brand-solid-hover shrink-0 cursor-default items-center rounded-full transition-all duration-200 ease-in-out",
              renderProps.isSelected &&
                "bg-bg-brand-solid shadow-[0_0_0_4px_rgba(var(--accent-color),0.1)]",
              renderProps.isDisabled && "bg-gray-100 opacity-50",
              renderProps.isFocusVisible && focusOutlineStyle,
              "cursor-pointer",
            )}
          >
            <span
              className={twMerge(
                "h-4 w-4 translate-x-0.5 transform rounded-full bg-fg-white shadow-md transition-transform duration-200 ease-in-out",
                renderProps.isSelected &&
                  "translate-x-[18px] border-white shadow-md rtl:-translate-x-[18px]",
                renderProps.isDisabled && "opacity-50",
              )}
            />
          </div>
          {typeof children === "function" ? children(renderProps) : children}
        </>
      )}
    </RACSwitch>
  );
}
