import { useQuery } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { Button as RACButton } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { env } from "@/config/env";
import apiClient from "@/lib/api/client";

import { Tooltip, TooltipTrigger } from "../../tooltip";
import User from "../icons/user.svg?react";
import Users from "../icons/users.svg?react";

type TeamMember = {
  email: string;
  id: string;
  name: string;
};

type TeamMembersResponse = {
  teamMembers: TeamMember[];
};

type NavItemProps = {
  isCollapsed: boolean;
  userId: number;
};

const navItemStyles = {
  base: "mx-auto flex h-6xl items-center gap-md rounded-sm px-lg py-md text-md font-semibold text-text-secondary hover:bg-bg-primary-hover",
  button: "w-full outline-none",
  icon: "size-6 text-nav-item-icon-fg",
};

function useTeamMembers(userId: number) {
  return useQuery<TeamMembersResponse>({
    queryFn: () => apiClient.url(`/users/${userId}/team-members`).get().json(),
    queryKey: ["user", userId, "team-members"],
  });
}

type TeamMemberItemProps = {
  isCollapsed: boolean;
  member: TeamMember;
};

function TeamMemberItem({ isCollapsed, member }: TeamMemberItemProps) {
  return (
    <li>
      <TooltipTrigger delay={250}>
        <RACButton className={navItemStyles.button}>
          <a
            className={navItemStyles.base}
            href={`${env.VITE_BASE_URL}/user-requests/${member.id}`}
          >
            <Users className={twMerge(navItemStyles.icon, "shrink-0")} />
            <span className="truncate">{member.name}</span>
          </a>
        </RACButton>
        {isCollapsed && <Tooltip placement="right">{member.name}</Tooltip>}
      </TooltipTrigger>
    </li>
  );
}

function ViewMoreItem({ isCollapsed }: { isCollapsed: boolean }) {
  return (
    <li>
      <TooltipTrigger delay={250}>
        <RACButton className={navItemStyles.button}>
          <a className={navItemStyles.base} href={`${env.VITE_BASE_URL}/users`}>
            <span className="truncate">View more</span>
          </a>
        </RACButton>
        {isCollapsed && <Tooltip placement="right">View more</Tooltip>}
      </TooltipTrigger>
    </li>
  );
}

export function TeamMembersNavItem({ isCollapsed, userId }: NavItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, isError, isLoading } = useTeamMembers(userId);

  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  if (isError) {
    return null;
  }

  return (
    <>
      <li>
        <TooltipTrigger delay={250}>
          <RACButton
            aria-expanded={isExpanded}
            className={navItemStyles.button}
            onPress={toggleExpanded}
          >
            <div className={navItemStyles.base}>
              <User className={navItemStyles.icon} />
              {!isCollapsed && (
                <>
                  Users
                  {isExpanded && (
                    <ChevronDown
                      className={twMerge(
                        "ml-auto size-6 shrink-0 text-gray-500 transition-transform",
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </RACButton>
          {isCollapsed && <Tooltip placement="right">Users</Tooltip>}
        </TooltipTrigger>
      </li>

      {isExpanded && !isLoading && (
        <ul className="space-y-1">
          {data?.teamMembers.map((member) => (
            <TeamMemberItem
              isCollapsed={isCollapsed}
              key={member.id}
              member={member}
            />
          ))}
          <ViewMoreItem isCollapsed={isCollapsed} />
        </ul>
      )}
    </>
  );
}
