import type { UseFormReturn, UseFormSetError } from "react-hook-form";

import { nanoid } from "nanoid";

import authenticatedApi from "@/lib/api/client";

import type {
  CreateGraphicRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateGraphicRequestForm";
import type { LinkFormValues } from "../useLinkForm";
import type { AssetField } from "../utils/uploadUtils";

import { LINK_TYPE, MAX_FILES } from "../utils/uploadUtils";

export function useLinkManagement(
  field: AssetField,
  assets: (FileAsset | LinkAsset)[],
  methods: UseFormReturn<CreateGraphicRequestFormData>,
  setError: (error: null | string) => void,
  setLinkFormError: UseFormSetError<LinkFormValues>,
): {
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
} {
  const handleLinkAdd = async (url: { link: string }) => {
    if (assets.length >= MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      // Hack to set useLinkForm formState.isSubmitSuccessful to false and prevent it from resetting the form
      setLinkFormError("root", {
        type: "added-assets-limit-reached",
      });

      return;
    }

    const response = await authenticatedApi
      .url("/attachments/upload-link")
      .post({
        type: LINK_TYPE[field],
        url: url.link,
      })
      .json<{ attachmentId: number }>();

    methods.setValue(field, [
      { attachmentId: response.attachmentId, id: nanoid(), url: url.link },
      ...assets,
    ]);
  };

  const handleLinkDelete = (linkId: string) => {
    setError(null);

    const filteredAssets = assets.filter((asset) => asset.id !== linkId);

    methods.setValue(field, filteredAssets);
  };

  return { handleLinkAdd, handleLinkDelete };
}
