interface LogoSectionProps {
  isCollapsed: boolean;
}

export function LogoSection({ isCollapsed }: LogoSectionProps) {
  if (isCollapsed) {
    return (
      <a href="/requests">
        <svg
          className="mx-auto"
          fill="none"
          height="32"
          viewBox="0 0 32 32"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.2885 0H0V10.7568H13.7097L1.88932 22.627L9.46275 30.2324L21.2885 18.3622V32H32V0H21.2885Z"
            fill="#7600FF"
          />
        </svg>
      </a>
    );
  }

  return (
    <a className="ml-3xl block" href="/requests">
      <svg
        fill="none"
        height="28"
        viewBox="0 0 143 28"
        width="143"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="fill-text-primary"
          d="M12.9499 27.3717V15.9798C12.9499 13.2048 11.982 11.9112 9.71245 11.9112C7.21903 11.9112 6.17641 13.279 6.17641 16.054V27.3717H0V6.84351H5.76822V9.025H5.84286C7.14439 6.9177 8.96839 6.14108 11.4618 6.14108C15.1821 6.14108 19.1287 8.21129 19.1287 14.2782V27.3717H12.9499Z"
        />
        <path
          className="fill-text-primary"
          d="M33.6787 28C27.3903 28 22.5528 23.3774 22.5528 17.0879C22.5528 10.7985 27.5023 6.17586 33.6787 6.17586C39.8551 6.17586 44.8046 10.7985 44.8046 17.0137C44.8046 23.229 39.8924 27.9977 33.6787 27.9977V28ZM38.6282 17.0879C38.6282 14.3501 36.5453 11.9089 33.6414 11.9089C30.924 11.9089 28.7292 14.2759 28.7292 17.0879C28.7292 19.9 30.8867 22.2669 33.6787 22.2669C36.4707 22.2669 38.6282 19.8258 38.6282 17.0879Z"
        />
        <path
          className="fill-text-primary"
          d="M47.9301 27.3717V0H54.1065V27.3694H47.9301V27.3717Z"
        />
        <path
          className="fill-text-primary"
          d="M58.5732 4.66203V0H64.7497V4.65971H58.5732V4.66203ZM58.5732 27.3717V6.84352H64.7497V27.3717H58.5732Z"
        />
        <path
          className="fill-text-primary"
          d="M94.7454 27.3717V16.3136C94.7454 13.0959 93.7027 11.9112 91.5825 11.9112C89.2384 11.9112 88.1584 13.3161 88.1584 16.0169V27.3717H81.982V16.054C81.982 13.3903 81.014 11.9112 78.8938 11.9112C76.475 11.9112 75.3951 13.4645 75.3951 16.3136V27.3717H69.2187V6.84351H74.9869V9.025H75.0615C76.1415 7.10084 78.1124 6.14108 80.7551 6.14108C83.3978 6.14108 85.4061 7.47176 86.7076 9.80162C88.1957 7.47176 90.4279 6.14108 93.2572 6.14108C97.8709 6.14108 100.924 8.95314 100.924 14.6839V27.3694H94.7477L94.7454 27.3717Z"
        />
        <path
          className="fill-text-primary"
          d="M105.276 4.66203V0H111.453V4.65971H105.276V4.66203ZM105.276 27.3717V6.84352H111.453V27.3717H105.276Z"
        />
        <path
          className="fill-text-primary"
          d="M117.333 27.3717V11.5032H114.282V6.84352H117.333V0H123.509V6.84352H126.486V11.5032H123.509V27.3717H117.333Z"
        />
        <path
          d="M129.133 1.28344e-06V4.61335H135.074L129.952 9.70426L133.234 12.9661L138.358 7.87515V13.7241H143V1.28344e-06H129.133Z"
          fill="#7600FF"
        />
      </svg>
    </a>
  );
}
