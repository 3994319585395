import type { UseFormReturn } from "react-hook-form";

import apiClient from "@/lib/api/client";

import type {
  CreateGraphicRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateGraphicRequestForm";

export const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB
export const MAX_FILES = 25;
export const ALLOWED_EXTENSIONS = [
  ".jpg",
  ".png",
  ".jpeg",
  ".pdf",
  ".heic",
  ".svg",
  ".eps",
  ".webp",
  ".tiff",
  ".gif",
  ".mp4",
  ".m4v",
  ".psd",
  ".ai",
  ".indd",
  ".prproj",
  ".aep",
  ".fig",
  ".mp3",
  ".wav",
  ".bmp",
  ".xd",
  ".html",
  ".doc",
  ".docx",
  ".txt",
  ".ppt",
  ".key",
  ".numbers",
  ".pages",
  ".cdr",
  ".raw",
  ".mid",
  ".midi",
  ".m4a",
  ".ogg",
  ".avi",
  ".mpg",
  ".mpeg",
  ".m4v",
  ".rar",
  ".zip",
  ".xls",
  ".xlsx",
  ".csv",
  ".mov",
  ".ttf",
  ".otf",
  ".pptx",
];

export const isFileAsset = (asset: FileAsset | LinkAsset): asset is FileAsset =>
  "file" in asset;

export type AssetField = "assets" | "inspirations";
export const LINK_TYPE = {
  assets: "asset",
  inspirations: "example",
} as const;

type UploadUrlFields = {
  attachmentId: number;
  fields: Record<string, string>;
  presignedUrl: string;
  url: string;
};
export async function getUploadUrlFields(
  fileName: string,
  fileSize: number,
  field: AssetField,
): Promise<UploadUrlFields> {
  return apiClient
    .query({
      fileName,
      size: fileSize,
      type: field === "assets" ? "asset" : "example",
    })
    .get("/attachments/upload-url")
    .json<UploadUrlFields>();
}
export async function uploadFile(
  newFile: FileAsset,
  setError: (error: null | string) => void,
  methods: UseFormReturn<CreateGraphicRequestFormData>,
  field: "assets" | "inspirations",
): Promise<void> {
  try {
    if (newFile.file.size > MAX_FILE_SIZE) {
      setError(`File ${newFile.file.name} exceeds the maximum size of 200MB.`);
      methods.setValue(
        field,
        methods
          .getValues(field)
          .map((asset) =>
            asset.id === newFile.id
              ? { ...asset, status: "error" as const }
              : asset,
          ),
      );

      return;
    }

    const uploadUrlFields = await getUploadUrlFields(
      newFile.file.name,
      newFile.file.size,
      field,
    );

    const formData = new FormData();

    for (const [key, value] of Object.entries(uploadUrlFields.fields)) {
      formData.append(key, value as string);
    }

    formData.append("file", newFile.file);

    const uploadResponse = await fetch(uploadUrlFields.presignedUrl, {
      body: formData,
      method: "POST",
    });

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed with status ${uploadResponse.status}`);
    }

    methods.setValue(
      field,
      methods.getValues(field).map((asset) =>
        asset.id === newFile.id
          ? {
              ...asset,
              attachmentId: uploadUrlFields.attachmentId,
              awsKey: uploadUrlFields.fields.key as string,
              status: "success" as const,
            }
          : asset,
      ),
    );
  } catch (error) {
    console.error(`Error uploading file ${newFile.file.name}:`, error);
    methods.setValue(
      field,
      methods
        .getValues(field)
        .map((asset) =>
          asset.id === newFile.id
            ? { ...asset, status: "error" as const }
            : asset,
        ),
    );
  }
}

export function hasDuplicateFileName(
  newFileName: string,
  existingAssets: (FileAsset | LinkAsset)[],
): boolean {
  return existingAssets
    .filter((asset): asset is FileAsset => "file" in asset)
    .some((asset) => asset.file.name === newFileName);
}
