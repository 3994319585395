import { useSearch } from "@tanstack/react-router";

import { useUser } from "./useUser";

export function useGetCurrentProjectId(): number {
  const { ["project-id"]: projectId } = useSearch({ strict: false });
  const { data: user } = useUser();

  return (projectId ?? user?.currentProjectId) as number;
}
