import type { UseEditorOptions } from "@tiptap/react";
import type { ForwardedRef } from "react";

import { useIsMutating } from "@tanstack/react-query";
import React, { useEffect } from "react";

import type { TooltipProps } from "@/components/tooltip";

import { Description } from "@/components/field";

import { EditorContainer } from "./EditorContainer";
import { EditorLabel } from "./EditorLabel";
import { useInitializeEditor } from "./useInitializeEditor";

export type TiptapEditorProps = {
  className?: string;
  description?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  onChangeHTML?: (html: string) => void;
  placeholder?: string;
  tooltipProps?: TooltipProps;
  value?: string;
} & UseEditorOptions;

const TiptapEditorComponent = React.forwardRef(
  (
    {
      className,
      description,
      errorMessage,
      isDisabled,
      isInvalid,
      isRequired,
      label,
      onChange,
      onChangeHTML,
      placeholder,
      tooltipProps,
      value,
      ...editorProps
    }: TiptapEditorProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const isPending = Boolean(
      useIsMutating({
        mutationKey: ["createOutputEnhancement"],
        status: "pending",
      }),
    );

    const editor = useInitializeEditor({
      className,
      isDisabled,
      onChange,
      onChangeHTML,
      placeholder,
      value,
      ...editorProps,
    });

    // Update editor content when value changes only for streamed AI responses
    useEffect(() => {
      if (editor && value && isPending) {
        editor.commands.setContent(value);
      }
    }, [editor, value, isPending]);

    if (!editor) {
      return null;
    }

    const focusEditor = () => {
      editor.commands.focus();
    };

    return (
      <div className="flex flex-col">
        <EditorLabel
          focusEditor={focusEditor}
          isRequired={isRequired}
          label={label}
          tooltipProps={tooltipProps}
        />
        <EditorContainer
          editor={editor}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          placeholder={placeholder}
          ref={ref}
        />
        {!isInvalid && description && <Description>{description}</Description>}
        {isInvalid && errorMessage && (
          <span className="pt-sm text-sm text-text-error-primary">
            {errorMessage}
          </span>
        )}
      </div>
    );
  },
);

TiptapEditorComponent.displayName = "TiptapEditor";

export const TiptapEditor = TiptapEditorComponent;
