import { ControlledTiptapEditor } from "@/components/ui/controlled/ControlledTiptapEditor";

import { useCreateVideoRequestFormContext } from "../useCreateVideoRequestForm";

export function SingleInputForm() {
  const methods = useCreateVideoRequestFormContext();

  return (
    <>
      <div />
      <ControlledTiptapEditor
        className="h-[480px]"
        control={methods.control}
        isRequired
        label="📄 Request Description"
        name="briefCreatorSingle.requestDescriptionHTML"
        onChangeHTML={(html) =>
          methods.setValue("briefCreatorSingle.requestDescriptionHTML", html)
        }
        onChangeText={(text) =>
          methods.setValue("briefCreatorSingle.requestDescriptionText", text)
        }
        placeholder="Tell us about your request. The more specific you are, the better the end result(s) will be. "
        tooltipProps={{
          children:
            "Describe the overall purpose and specific requirements of your design request. Include any critical project details that will guide the creation process.",
        }}
      />
      <ControlledTiptapEditor
        className="h-[480px]"
        control={methods.control}
        isRequired
        label="💬 Texts for Design"
        name="briefCreatorSingle.textsForDesignHTML"
        onChangeHTML={(html) =>
          methods.setValue("briefCreatorSingle.textsForDesignHTML", html)
        }
        onChangeText={(text) =>
          methods.setValue("briefCreatorSingle.textsForDesignText", text)
        }
        placeholder="Tell us what text should be displayed on your design. "
        tooltipProps={{
          children:
            "Enter the exact text, key messages, and calls to action that should be featured on the design. Specify any slogans or phrases to ensure they align with your communication goals.",
        }}
      />
    </>
  );
}
