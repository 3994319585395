import { WandIcon } from "lucide-react";

import { Button } from "@/components/button";
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogFooter,
} from "@/components/dialog";
import { Modal } from "@/components/modal";

interface SubmitWarningModalProps {
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SubmitWarningModal({
  isOpen,
  onOpenChange,
}: SubmitWarningModalProps) {
  return (
    <Modal isDismissable isOpen={isOpen} onOpenChange={onOpenChange}>
      <Dialog>
        <DialogBody className="flex flex-row gap-xl">
          <div className="grid size-6xl shrink-0 place-items-center rounded-full bg-bg-warning-secondary">
            <WandIcon className="text-featured-icon-light-fg-warning" />
          </div>
          <div className="flex flex-col gap-xs">
            <span className="text-lg font-semibold text-text-primary">
              Improve Brief before submitting
            </span>
            <DialogCloseButton />
            <span className="text-sm text-text-tertiary">
              In Magical Brief Form it is mandatory to enchance the brief with
              Ai. In order to do so, click the “Improve Brief” button in the
              Magical Brief Creator section.
            </span>
          </div>
        </DialogBody>
        <DialogFooter>
          <DialogCloseButton>Cancel</DialogCloseButton>
          <Button
            onPress={() => onOpenChange(false)}
            size="lg"
            variant="secondary"
          >
            OK
          </Button>
        </DialogFooter>
      </Dialog>
    </Modal>
  );
}
